import React, {useEffect, useState} from 'react';
import $api from "../http";
import "../css/card-creator.css"
import ModalDialog from "./ModalDialog";
import {useNavigate} from "react-router-dom";
import OKSvg from '../img/ok-svgrepo-com.svg'
import BatmanLogoSvg from "../img/batman-logo-thin.svg";
import {addDevidersToString} from "./helpers/helper-functions";

const CardViewer = ({store, traders, updatedDevice, freshCard, completedOrder}) => {

    const navigate = useNavigate()

    const [activeModalInfo, setActiveModalInfo] = useState("no")
    const [activeModalConfirm, setActiveModalConfirm] = useState("no")
    const [activeModalQr, setActiveModalQr] = useState("no")
    const [qrData, setQrData] = useState("")
    const [qrName, setQrName] = useState("")

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")

    const [processing, setProcessing] = useState({status: false, index: null})

    const [cardToDelete, setCardToDelete] = useState()
    const [cardToUnbind, setCardToUnbind] = useState()


    const [filter, setFilter] = useState({})


    const startCardTest = async (cardId) => {
        console.log(cardId)

        const response = await $api.get(`/card_test?cardId=${cardId}`)

        console.log(response.data)

        // Получаем обновленный объект карты из ответа
        const updatedCard = response.data;

        // Обновляем состояние карт
        setCards(prevCards =>
            prevCards.map(card =>
                card._id === updatedCard._id ? updatedCard : card
            )
        );

    }


    const getCards = async (filter) => {

        filter = filter ? encodeURIComponent(JSON.stringify(filter)) : ""

        const response = await $api.get(`/get_cards?filter=${filter}`)
        console.log(response.data)
        setCards(response.data)
    }

    const deleteCard = async (cardId) => {


        try {
            const response = await $api.delete(`/delete_card?cardId=${cardId}`)
            console.log(response.data)
            if(response.data === "OK") {

                setModalMessage(`Карта удалена!`)
                setActiveModalInfo("progress")
                setTimeout(() => {
                    setActiveModal("active")
                }, 100)

                setCards(prev => {
                    const newCardsState = prev.filter(card => card._id !== cardId)
                    return newCardsState
                })
            } else {
                setModalMessage(`Это не ваша карта, удалить ее нельзя!`)
                setActiveModalInfo("progress")
                setTimeout(() => {
                    setActiveModal("active")
                }, 100)
            }
        } catch (e) {
            setModalMessage(e.message)
            setActiveModalInfo("progress")
            setTimeout(() => {
                setActiveModal("active")
            }, 100)
        }



    }




    const unbindCard = async (cardId) => {


        try {
            const response = await $api.delete(`/unbind_card?cardId=${cardId}`)
            console.log(response.data)
            if(response.data._id) {

                setModalMessage(`Карта отвязана от устройства!`)
                setActiveModalInfo("progress")
                setTimeout(() => {
                    setActiveModal("active")
                }, 100)

                setCards(prev => {
                    const newCardsState = prev.map(card => card._id !== cardId ? card : response.data)
                    return newCardsState
                })
            } else {
                setModalMessage(`Это не ваша карта, отвязать ее нельзя!`)
                setActiveModalInfo("progress")
                setTimeout(() => {
                    setActiveModal("active")
                }, 100)
            }
        } catch (e) {
            setModalMessage(e.message)
            setActiveModalInfo("progress")
            setTimeout(() => {
                setActiveModal("active")
            }, 100)
        }



    }


    const activateCardWithoutTest = async (cardId, index) => {




        try {
            setProcessing({status: true, index})



            const response = await $api.get(`/activate_card_without_test?cardId=${cardId}`)
            console.log(response.data)


            // Получаем обновленный объект карты из ответа
            const updatedCard = response.data;

            // Обновляем состояние карт
            setCards(prevCards =>
                prevCards.map(card =>
                    card._id === updatedCard._id ? updatedCard : card
                )
            );
            setProcessing({status: false, index})

        } catch (e) {
            setModalMessage("Ошибка смены статуса карты\n" + e.message + "\n зовите админа")
            setActiveModal("progress")
            setTimeout(() => {
                setActiveModal("active")
            }, 100)
            setProcessing({status: false, index})
        }


    }



    const changeCardStatus = async (cardId, index) => {




        try {
            setProcessing({status: true, index})



            const response = await $api.get(`/change_card_status?cardId=${cardId}`)
            console.log(response.data)


            // Получаем обновленный объект карты из ответа
            const updatedCard = response.data;

            // Обновляем состояние карт
            setCards(prevCards =>
                prevCards.map(card =>
                    card._id === updatedCard._id ? updatedCard : card
                )
            );
            setProcessing({status: false, index})

        } catch (e) {
            setModalMessage("Ошибка смены статуса карты\n" + e.message + "\n зовите админа")
            setActiveModal("progress")
            setTimeout(() => {
                setActiveModal("active")
            }, 100)
            setProcessing({status: false, index})
        }


    }



    const [cards, setCards] = useState([])


    useEffect(() => {
        store.setPageTitle("Все карты")

        if(store.user.roles.includes("support") && Object.keys(filter).length) {
            getCards(filter)
        } else {
            getCards()
        }

    }, [filter]);


    useEffect(() => {


        if(freshCard && Object.keys(freshCard).length) {

            // Обновляем состояние карт
            setCards(prevCards =>
                prevCards.map(card =>
                    card._id === freshCard._id ? freshCard : card
                )
            );
        }


    }, [freshCard])


    useEffect(() => {

        if(completedOrder && Object.keys(completedOrder).length) {
            const { card, amount } = completedOrder;
            const cardId = card._id;

            // Используем setCards, чтобы обновить состояние
            setCards(prevCards => {
                return prevCards.map(c => {
                    // Если ID карты совпадает, обновляем reminderOfLimit
                    if (c._id === cardId) {
                        return {
                            ...c,
                            reminderOfLimit: c.reminderOfLimit - amount, // Отнимаем amount
                        };
                    }
                    return c; // Оставляем карту без изменений
                });
            });
        }

    }, [completedOrder])


    useEffect(() => {

        console.log(updatedDevice)
        if(store.user.roles.includes("support")) {

            console.log("THIIS IS SUPPORT EFFECT")

            if(updatedDevice?.user === filter.traderId || updatedDevice?.user?._id === filter.traderId || updatedDevice?.isCard) {


                console.log("SUPPORT TRY TO SETTING CARDS")

                setCards(prev => {
                    const newCardsState = prev?.map(card => {
                        // console.log(card)
                        if(card.device?._id === updatedDevice?._id) {
                            card.device.online = updatedDevice.online
                            // card.device.workStatus = updatedDevice.workStatus
                        } else if (card._id === updatedDevice?.card?._id) {

                            card.device = updatedDevice

                        } else if(card._id === updatedDevice?._id) {

                            console.log("RETURNING UPDATED DEVICE")
                            return updatedDevice
                        }

                        return card

                    })

                    return newCardsState
                })

            } else {
                console.log("ТУТ ЧЕГО ТО НЕТ")
            }

        }

        if(store.user.roles.includes("trader")) {

            setCards(prev => {

                const newCardsState = prev?.map(card => {
                    // console.log(card)
                    //этот блок
                    if(card.device?._id === updatedDevice?._id || card.device?._id === updatedDevice?.card?._id) {

                        if(card.device) {
                            card.device.online = updatedDevice.online
                        }

                        //этот блок работает когда нужно обновить стейт вновь привязываемого устройства к карте
                    } else if (card._id === updatedDevice?.card?._id) {

                        card.device = updatedDevice

                    } else if(card._id === updatedDevice?._id) {
                        return updatedDevice
                    }

                    return card

                })

                return newCardsState
            })
        }



    }, [updatedDevice, filter, store.user])



    return (
        <div className={"card-viewer-wrapper"}>

            {store.user.roles.includes("support")
                ?
                <div className={"order-viewer-merchant-sort-wrapper"}>

                    {/*<select*/}

                    {/*    value={filter?.status}*/}
                    {/*    onChange={(e) => {*/}

                    {/*        setAppeals([])*/}
                    {/*        setProcessing(true)*/}
                    {/*        setSkipCounter(0);*/}

                    {/*        setFilter(prev => ({*/}
                    {/*            ...prev,*/}
                    {/*            status: e.target.value*/}
                    {/*        }))*/}


                    {/*        setTimeout(() => {*/}
                    {/*            setStopFetching(false)*/}
                    {/*            setFetching(true)*/}
                    {/*        }, 1000)*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <option value={"pending"}>Открытые</option>*/}
                    {/*    <option value={"closed"}>Закрытые</option>*/}

                    {/*</select>*/}

                    <select
                        value={filter.traderId ?? ""}
                        onChange={(e) => {
                            const selectedTrader = traders.find(trader => trader.id === e.target.value);

                            setFilter(prev => ({
                                ...prev,
                                traderId: e.target.value,
                                traderLogin: selectedTrader?.login || ""
                            }));

                            setTimeout(() => {
                                // setStopFetching(false);
                                // setFetching(true);
                            }, 1000);
                        }}
                    >
                        <option disabled value="">
                            Чьи карты будем смотреть?
                        </option>
                        {Object.values(traders)
                            .sort((a, b) => a.login.localeCompare(b.login))
                            .map(trader => (
                                <option key={trader.id} value={trader.id}>
                                    {trader.login}
                                </option>
                            ))}
                        {/*{traders.map(trader => (*/}
                        {/*    <option key={trader.id} value={trader.id}>*/}
                        {/*        {trader.login}*/}
                        {/*    </option>*/}
                        {/*))}*/}
                    </select>


                    {/*<button*/}
                    {/*    className={"gradient-dark-button"}*/}
                    {/*    onClick={() => {*/}

                    {/*        setAppeals([])*/}
                    {/*        setProcessing(true)*/}
                    {/*        setSkipCounter(0);*/}

                    {/*        setFilter({status: "pending", onlyMine: store.user.id, startDate: "", stopDate: ""})*/}


                    {/*        setTimeout(() => {*/}
                    {/*            setStopFetching(false)*/}
                    {/*            setFetching(true)*/}
                    {/*        }, 1000)*/}
                    {/*    }}*/}
                    {/*>Сброс*/}
                    {/*</button>*/}


                </div>
                :
                null
            }


            <table className="transaction-table">
                <thead>
                <tr>
                    <th>ФИО</th>
                    {store.user.roles.includes("trader")
                        ?
                        <th>Токен</th>
                        :
                        null
                    }

                    <th>Банк</th>
                    <th>Траффик</th>
                    <th>Номер</th>
                    {/*{store.user.roles.includes("support")*/}
                    {/*    ?*/}
                    <th>Устройство</th>
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}
                    <th className={"fixed-table-width"}>Активность</th>
                    {store.user.roles.includes("trader")
                        ?
                        <th className={"fixed-table-width"}>Статистика</th>
                        :
                        null
                    }

                    <th className={"fixed-table-width"}>Управление</th>



                </tr>
                </thead>
                <tbody>
                {cards.map((card, index) => {
                    // const formattedDate = new Date(card.block_timestamp)
                    //     .toLocaleString('ru-RU', options);

                    // console.log(card)
                    const percent = parseFloat(((card.reminderOfLimit / card.dayLimit) * 100).toFixed(1));


                    const getColor = (percent) => {
                        if (percent < 20) return '#F28B82'; // Нежный красный
                        if (percent < 50) return '#FDD663'; // Нежный жёлтый
                        return '#81C995'; // Нежный зелёный
                    };

                    return (
                        <tr key={card._id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                            <td>{card.cardholder}</td>
                            {store.user.roles.includes("trader")
                                ?
                                <td>
                                    {card.token
                                        ?
                                        <button
                                            className="gradient-orange-button qr-token-btn"
                                            onClick={() => {
                                                setQrData(card.token?.token)
                                                setQrName(card.cardholder + "\n" + card.token?.token)
                                                setActiveModalQr("progress")
                                                setTimeout(() => {
                                                    setActiveModalQr("active")
                                                }, 100)
                                            }}
                                        >
                                            Токен
                                        </button>
                                        :
                                        null
                                    }
                                    {/*<span>{card.token?.token}</span>*/}

                                </td>
                                :
                                null
                            }
                            <td>{card.bank}</td>
                            <td>{card.trafficType}</td>
                            <td title={card.number}>{"*" + card.number.slice(-4)}</td>
                            {/*{store.user.roles.includes("support")*/}
                            {/*    ?*/}
                                <td>
                                    {card.device?.online // && card.device?.workStatus
                                        ?
                                        <div className={"green-bold"}>OnLine</div>
                                        :
                                        // !card.device?.workStatus
                                        //     ?
                                            card.device
                                                ?
                                                <div className={"red-bold"}>OffLine</div>
                                                :
                                                <div className={"red-bold"}>Не привязано</div>


                                    }
                                </td>
                            {/*    :*/}
                            {/*    null*/}
                            {/*}*/}
                            <td>
                                {store.user.roles.includes("trader")
                                    ?
                                    card.stopByFails
                                        ?
                                        card.test
                                            ?
                                            "⚠️ На тесте"
                                            :
                                            "⚠️ Остановлена системой"
                                        :
                                        card.stopByAdmin
                                            ?
                                            "⚠️ Остановлена админом"
                                            :
                                            card.active
                                                ?
                                                card.device?.online
                                                    ?
                                                    card.reminderOfLimit < card.min
                                                        ?
                                                        <span title={"Эта карта больше не получит заявок, так как ее минимальный разрешенный платеж превышает ее суточный остаток"}>
                                                            ⚠️ - не работает</span>
                                                        :
                                                        <div className={"green-bold"}>В работе</div>
                                                    :
                                                    <div className={"red-bold"}>Ожидание соединения</div>
                                                :
                                                <div className={"red-bold"}>Остановлена</div>
                                    :
                                    card.active && !card.stopByFails
                                        ?
                                        card.stopByAdmin
                                            ?
                                            "⚠️ Остановлена админом"
                                            :
                                            card.device?.online
                                                ?
                                                <div className={"green-bold"}>В работе</div>
                                                :
                                                <div className={"red-bold"}>Ожидание соединения</div>

                                        :
                                        card.stopByFails
                                            ?
                                            card.test
                                                ?
                                                <div>
                                                    ⚠️ На тесте
                                                    <button
                                                        onClick={() => activateCardWithoutTest(card._id, index)}
                                                        className={"gradient-green-button"}>Вкл.</button>
                                                </div>
                                                :
                                                <div>
                                                    ⚠️ Остановлена системой
                                                    <button
                                                        onClick={() => activateCardWithoutTest(card._id, index)}
                                                        className={"gradient-green-button"}>Вкл.</button>
                                                </div>

                                            :
                                            "⚠️ Остановлена трейдером"

                                }

                            </td>

                            {store.user.roles.includes("trader")
                                ?
                                <td>
                                    {card.stopByFails
                                        ?
                                        card.test
                                            ?
                                            card.test.numberAmount > 1
                                                ?
                                                `Ждем перевод ${card.test.numberAmount}р. по номеру карты`
                                                :
                                                card.test.sbpAmount
                                                    ?
                                                    <>
                                                        {card.test.numberAmount === 1 ?
                                                            <span>По номеру ОК<img height={"15px"}
                                                                                   src={OKSvg}/><br/><br/></span> : ""}
                                                        Ждем перевод {card.test.sbpAmount}р. {card.trafficType === "nspk" ? "по QR-коду" : "по СБП"}
                                                    </>
                                                    :
                                                    null
                                            :
                                            card.device
                                                ?
                                                <button
                                                    className={"gradient-green-button"}
                                                    onClick={() => startCardTest(card._id)}
                                                >Тестировать</button>
                                                :
                                                "Сначала привяжите устройство"
                                        :
                                        <div>
                                            <div className="limit-bar">
                                                <div
                                                    className="limit-fill"
                                                    style={{
                                                        width: `${percent}%`,
                                                        backgroundColor: getColor(percent),
                                                    }}
                                                ></div>
                                                <div className="limit-text">
                                                    {addDevidersToString(card.reminderOfLimit.toFixed(2))}
                                                </div>
                                            </div>
                                            <div className={"card-info-mini"}>В день: {card.todaysOrdersCount}/{!card.maxDailyOrders || card.maxDailyOrders === 0 ? "∞" : card.maxDailyOrders}</div>
                                            <div className={"card-info-mini"}>Интервал: {card.intervalBetweenTransactions ? card.intervalBetweenTransactions + " м." : "нет"}</div>
                                            <div className={"card-info-mini"}>Одновременно: {card.limitOfOpened ? card.limitOfOpened : "∞"}</div>

                                        </div>

                                        // `${card.reminderOfLimit}/${card.dayLimit}`
                                        // <img height={"30px"} src={OKSvg}/>
                                    }

                                </td>
                                :
                                null
                            }


                            <td>
                                <div className={"flex-table-cell-column"}>
                                    <div className={"flex-table-cell"}>
                                        {/*{card.isEditable || store.user.roles.includes('support')*/}
                                        {/*    ?*/}
                                        <button
                                            className={"gradient-orange-button"}
                                                onClick={() => {
                                                    store.setCardToEditId(card._id)
                                                    navigate('/create_card')

                                                }}
                                            >Изменить {
                                                processing.status && processing.index === index
                                                    ?
                                                    <img src={require('../img/loading.gif')} height={"15px"}
                                                         className={"margin-left-20"}/>
                                                    :
                                                    null
                                            }
                                            </button>
                                        {/*    :*/}
                                        {/*    null*/}
                                        {/*}*/}

                                        {store.user.roles.includes("support")
                                            ?
                                            !card.stopByAdmin
                                                ?
                                                <button
                                                    className={"gradient-red-button"}
                                                    onClick={() => {

                                                        changeCardStatus(card._id, index)

                                                    }}
                                                >Отключить {
                                                    processing.status && processing.index === index
                                                        ?
                                                        <img src={require('../img/loading.gif')} height={"15px"}
                                                             className={"margin-left-20"}/>
                                                        :
                                                        null
                                                }
                                                </button>
                                                :
                                                <button
                                                    className={"gradient-green-button card-status-switch-btn"}
                                                    onClick={() => {

                                                        changeCardStatus(card._id, index)
                                                    }}
                                                >Включить {processing.status && processing.index === index ?
                                                    <img src={require('../img/loading.gif')} height={"15px"}
                                                         className={"margin-left-20"}/> : null}</button>

                                            :
                                            card.active
                                                ?
                                                <button
                                                    className={"gradient-red-button"}
                                                    onClick={() => {

                                                        changeCardStatus(card._id, index)

                                                    }}
                                                >Отключить{
                                                    processing.status && processing.index === index
                                                        ?
                                                        <img src={require('../img/loading.gif')} height={"15px"}
                                                             className={"margin-left-20"}/>
                                                        :
                                                        null
                                                }
                                                </button>
                                                :
                                                <button
                                                    className={"gradient-green-button card-status-switch-btn"}
                                                    onClick={() => {

                                                        changeCardStatus(card._id, index)
                                                    }}
                                                >Включить {processing.status && processing.index === index ?
                                                    <img src={require('../img/loading.gif')} height={"15px"}
                                                         className={"margin-left-20"}/> : null}</button>
                                        }



                                    </div>
                                    <div className={"flex-table-cell"}>
                                        {card.device
                                            ?
                                            <button
                                                className={"gradient-orange-button"}
                                                onClick={() => {

                                                    setModalMessage(`Вы действительно хотите отвязать карту ${card.number}\n${card.cardholder} от устройства`)
                                                    setCardToDelete()
                                                    setCardToUnbind(card._id)
                                                    setActiveModalConfirm("progress")
                                                    setTimeout(() => {
                                                        setActiveModalConfirm("active")
                                                    }, 100)
                                                }}
                                            >Отвязать
                                            </button>
                                            :
                                            null
                                        }

                                        <button
                                            className={"gradient-dark-button"}
                                            onClick={() => {

                                                setModalMessage(`Вы действительно хотите удалить карту ${card.number}\n${card.cardholder}`)
                                                setCardToUnbind()
                                                setCardToDelete(card._id)
                                                setActiveModalConfirm("progress")
                                                setTimeout(() => {
                                                    setActiveModalConfirm("active")
                                                }, 100)
                                            }}
                                        >Удалить
                                        </button>
                                    </div>
                                </div>

                            </td>


                        </tr>
                    );
                })}
                </tbody>
            </table>


            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                // action={() => {
                //     setActiveModal("progress")
                //     setTimeout(() => {
                //         setActiveModal("no")
                //     }, 100)
                // }}
                message={modalMessage}
                purpose={"info"}
                buttonText2={"OK"}
            />
            <ModalDialog
                active={activeModalQr}
                setActive={setActiveModalQr}
                qrcode={qrData}
                message={qrName}
                purpose={"info"}
            />

            <ModalDialog
                active={activeModalConfirm}
                setActive={setActiveModalConfirm}
                message={modalMessage}
                purpose={"choice"}
                action={
                    cardToDelete
                        ? () => deleteCard(cardToDelete)
                        : () => unbindCard(cardToUnbind)
                }

            />

            <ModalDialog
                active={activeModalInfo}
                setActive={setActiveModalInfo}
                message={modalMessage}
                purpose={"info"}
                buttonText2={"OK"}
            />
        </div>
    );
};

export default CardViewer;