import React, {useState} from 'react';
import "../css/helpers.css"
import Switch from "./Switch";

const Plate = ({
                   plateTitle, plateImage, plateImageAlt, plateAmount, switcher,
                    actualUstdPrice, switcherPosition, localStorageItem, dollar
}) => {


    const [switchPosition, setSwitchPosition] = useState(switcherPosition)

    function addDevidersToString(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }

    return (
        <div className={"plate-wrapper"}>
            <div className={"plate-title-container"}>
                <h3 className={"plate-title"}>{plateTitle}</h3>
                {switcher
                    ?
                    <Switch
                        isChecked={switchPosition}
                        onChange={(e) => {
                            localStorage.setItem(localStorageItem, !switchPosition)
                            setSwitchPosition(prev => !prev)
                        }}
                    />
                    :
                    null
                }
            </div>

            <div className={"plate-content"}>
                <img
                    className={"plate-image"}
                    src={switcher
                            ?
                            switchPosition
                                ?
                                plateImageAlt
                                :
                                plateImage
                            :
                            dollar
                                ?
                                plateImageAlt
                                :
                                plateImage
                    }
                />
                <h4 className={"plate-amount"}>
                    {switcher
                        ?
                        switchPosition
                            ?
                            addDevidersToString(parseFloat(plateAmount).toFixed(2))
                            :
                            addDevidersToString((parseFloat(actualUstdPrice) * parseFloat(plateAmount)).toFixed(2))
                        :
                        dollar
                            ?
                            addDevidersToString(parseFloat(plateAmount).toFixed(2))
                            :
                            addDevidersToString(plateAmount)
                    }
                </h4>
            </div>

        </div>
    );
};

export default Plate;