import React, {useState, useEffect} from 'react';
import "../css/token-creator.css"
import $api from "../http";
import {useNavigate} from "react-router-dom";
import ModalDialog from "./ModalDialog";
import {addHyphens} from "./helpers/helper-functions";


const TokenCreator = ({store}) => {


    const [isSignature, setIsSignature] = useState(false)
    const [signatureKey, setSignatureKey] = useState('')
    const [tokenData, setTokenData] = useState([])

    const [signatureData, setSignatureData] = useState([])
    const [tokenName, setTokenName] = useState("")
    const [createError, setCreateError] = useState({show: false, message: ""})

    const [callbackUrl, setCallbackUrl] = useState()

    const [activeModal, setActiveModal] = useState("no")
    const [activeModalName, setActiveModalName] = useState("no")
    const [activeModalDelete, setActiveModalDelete] = useState("no")
    const [qrData, setQrData] = useState("")
    const [modalMessage, setModalMessage] = useState("")

    const [tokenToDelete, setTokenToDelete] = useState("")


    const [showOk, setShowOk] = useState(false)


    const getToken = async () => {
        const response = await $api.get('/get_merchant_tokens')
        console.log(response.data)
        if(response.data) {
            setTokenData([response.data])
        }

        if(response.data.merchantSignatureData) {

            setSignatureData([response.data.merchantSignatureData])
        }

    }


    useEffect(() => {
        store.setPageTitle("Токены")
        getToken()
    }, []);



    const deleteToken = async (tokenId, isSignature) => {


        try {
            const response = await $api.delete(`/delete_token?tokenId=${tokenId}`)

            console.log(response.data)

            if(response.data.status === "OK") {
                if(isSignature) {
                    setSignatureData([])
                } else {
                    setTokenData([])
                }

            }

        } catch (e) {
            setModalMessage(e.message)
            setActiveModal("progress")
            setTimeout(() => {
                setActiveModal("active")
            }, 100)

        }

    }

    const createToken = async (tokenName, callbackUrl, isSignature) => {

        console.log(tokenName, callbackUrl)
        // return


            try {
                const response = await $api.get(
                    `/create_token?tokenName=${tokenName}&callbackUrl=${callbackUrl}&isSignature=${isSignature}`
                )

                console.log(response.data)

                if(response.data.apiKeyData) {

                    if(isSignature) {
                        setSignatureData([response.data.apiKeyData])
                        setModalMessage(response.data.message + "\n" + response.data.apiKeyData.signature)
                    } else {
                        setTokenData([response.data.apiKeyData])
                        setModalMessage(response.data.message + "\n" + response.data.apiKeyData.token)
                    }



                } else {
                    setModalMessage(response.data.message)
                }

                setActiveModal("progress")
                setTimeout(() => {
                    setActiveModal("active")
                }, 100)

                // setTokenData(prev => [response.data, ...prev])

            } catch (e) {
                console.log(e)
                setCreateError({show: true, message: "Ошибка создания токена"})
            }


    };






    return (
        <div className={"token-creator-wrapper"}>

            <div className={"create-token-btn-container"}>
                {createError.show
                    ?
                    <h3 className={"create-user-error-message red-for-blue"}>{createError.message}</h3>
                    :
                    null
                }
                {showOk && !createError.show
                    ?
                    <h3 className={"create-user-success-message"}>Пользователь {store.userToEditId ? "сохранён" : "добавлен"} успешно!</h3>
                    :
                    null
                }
                {tokenData.length
                    ?
                    null
                    :
                    <button
                        className={"gradient-dark-button create-user-btn"}
                        onClick={() => {

                            setTokenName("")
                            setModalMessage("Данные для ключа")

                            setActiveModalName("progress")
                            setTimeout(() => {
                                setActiveModalName("active")
                            }, 100)
                        }}
                    >Создать
                    </button>
                }

            </div>

            {tokenData.length
                ?
                <table className="transaction-table">
                    <thead>
                    <tr>
                        <th>Токен</th>
                        <th>Создан</th>
                        <th>Callback URL</th>
                        <th>Удаление</th>

                    </tr>
                    </thead>

                    {tokenData.map((token, index) => {
                        // const formattedDate = new Date(card.block_timestamp)
                        //     .toLocaleString('ru-RU', options);

                        return (
                            <tr key={token._id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                <td>{token.name}</td>
                                <td>{new Date(token.creationDate).toLocaleString()}</td>
                                <td>{token.callbackUrl}</td>
                                <td>
                                    <button
                                        className="gradient-red-button"
                                        onClick={() => {
                                            setTokenToDelete(token._id)
                                            setIsSignature(false)
                                            setModalMessage(`Вы уверену что хотите удалить ключ "${token.name}"`)
                                            setActiveModalDelete("progress")
                                            setTimeout(() => {
                                                setActiveModalDelete("active")
                                            }, 100)
                                        }}
                                    >
                                        Удалить
                                    </button>
                                </td>

                            </tr>
                        );
                    })}
                    <tbody>

                    </tbody>
                </table>
                :
                <h1>У вас пока нет API-ключей</h1>
            }

            <div className={"create-token-btn-container"}>
                {signatureData.length
                    ?
                    null
                    :
                    <button
                        className={"gradient-dark-button create-user-btn"}
                        onClick={() => {

                            setTokenName("")
                            setModalMessage("Данные для ключа подписи")
                            setIsSignature(true)
                            setActiveModalName("progress")
                            setTimeout(() => {
                                setActiveModalName("active")
                            }, 100)
                        }}
                    >Создать ключ подписи
                    </button>
                }

            </div>
            {signatureData.length
                ?
                <table className="transaction-table">
                    <thead>
                    <tr>
                        <th>Токен</th>
                        <th>Создан</th>
                        <th>Удаление</th>

                    </tr>
                    </thead>

                    {signatureData.map((token, index) => {
                        // const formattedDate = new Date(card.block_timestamp)
                        //     .toLocaleString('ru-RU', options);

                        return (
                            <tr key={token._id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                <td>{token.name}</td>
                                <td>{new Date(token.creationDate).toLocaleString()}</td>

                                <td>
                                    <button
                                        className="gradient-red-button"
                                        onClick={() => {
                                            setTokenToDelete(token._id)
                                            setIsSignature(true)
                                            setModalMessage(`Вы уверену что хотите удалить ключ "${token.name}"`)
                                            setActiveModalDelete("progress")
                                            setTimeout(() => {
                                                setActiveModalDelete("active")
                                            }, 100)
                                        }}
                                    >
                                        Удалить
                                    </button>
                                </td>

                            </tr>
                        );
                    })}
                    <tbody>

                    </tbody>
                </table>
                :
                <h1>У вас пока нет ключа для подписи коллбэков</h1>
            }
            <ModalDialog
                active={activeModalName}
                setActive={setActiveModalName}
                purpose={"input"}
                action={() => createToken(tokenName, callbackUrl, isSignature)}
                message={modalMessage}
                inputValue={tokenName}
                setInputValue={setTokenName}
                inputPlaceHolder={"Имя ключа"}
                inputValue2={callbackUrl}
                setInputValue2={setCallbackUrl}
                input2={!isSignature}
                inputPlaceHolder2={"Callback URL"}
                buttonText1={"Создать"}
            />
            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                purpose={"info"}
                buttonText2={"OK"}
                message={modalMessage}
            />
            <ModalDialog
                active={activeModalDelete}
                setActive={setActiveModalDelete}
                action={() => deleteToken(tokenToDelete, isSignature)}
                purpose={"choice"}
                buttonText1={"Удалить"}
                message={modalMessage}
            />


        </div>
    );
};

export default TokenCreator;