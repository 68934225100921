import React, {useEffect, useState} from 'react';
import $api from "../../http";
import ModalDialog from "../ModalDialog";


const SupportOrderTable = ({orders, setOrders, processing}) => {

    const [activeAppealConstructModal, setActiveAppealConstructModal] = useState("no")
    const [activeAppealConfirmModal, setActiveAppealConfirmModal] = useState("no")
    const [activeAppealErrorModal, setActiveAppealErrorModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")

    const [orderToAppealing, setOrderToAppealing] = useState()
    const [amountToAppealing, setAmountToAppealing] = useState()
    const [checkFile, setCheckFile] = useState()

    const [errorMessage, setErrorMessage] = useState("")


    useEffect(() => {
        console.log(checkFile)
    }, [checkFile])

    const manualAppealOpen = async (order, amount, file) => {
        console.log(order)

        console.log("amount", amount)

        // return

        const appealData = {
            order_id: order.id,
            amount,
            merchantId: order.merchant._id,
            file
        }




        const formData = new FormData();
        formData.append('receipt', file);
        formData.append('order_id', order.id); // Укажите нужный номер платежа
        formData.append('amount', amount); // Укажите нужную сумму
        formData.append('merchantId', order.merchant._id); // Укажите нужную сумму



        try {

            const response = await $api.post(`/appeals_manual`, formData)
            console.log(response.data)

            if(response.data.message) {
                setErrorMessage(response.data.message)

                setActiveAppealErrorModal("progress")
                setTimeout(() => {
                    setActiveAppealErrorModal("active")
                }, 100)

            }


            setOrders(prev => {
                const newOrderState = prev.map(order => {
                    if(order.id === response.data._id) {
                        return response.data
                    } else {
                        return order
                    }
                })

                return newOrderState

            })

        } catch (e) {

            setErrorMessage(e.message)

            setActiveAppealErrorModal("progress")
            setTimeout(() => {
                setActiveAppealErrorModal("active")
            }, 100)

        }




    }



    return (

        orders.length
            ?
            <table className="transaction-table">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Дата</th>
                    <th>Мерчант</th>
                    <th>Трейдер</th>
                    <th>Сумма (руб)</th>
                    {/*<th>Сумма (usdt)</th>*/}
                    <th>Карта</th>
                    <th>Банк</th>
                    <th>Тип трафика</th>
                    <th>Статус</th>
                    {/*<th className={"fixed-table-width"}>Профит (usdt)</th>*/}
                    <th className={"fixed-table-width"}>Управление</th>

                </tr>
                </thead>
                <tbody>
                {orders?.map((order, index) => {
                    // const formattedDate = new Date(card.block_timestamp)
                    //     .toLocaleString('ru-RU', options);

                    return (
                        <tr
                            key={order.id || order._id}
                            className={
                                order.status === "failed"
                                    ?
                                    "red-row"
                                    :
                                    order.status === "opened"
                                        ?
                                        "green-row"
                                        :
                                        index % 2 === 0
                                            ?
                                            'even-row'
                                            :
                                            'odd-row'
                            }
                        >
                            <td>{order.number}</td>
                            <td>{new Date(order.openDate).toLocaleString([], {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: '2-digit',
                                minute: '2-digit'
                            })}</td>
                            <td>{order.merchant?.login}</td>
                            <td>{order.trader.login}</td>
                            <td>{order.amount}</td>
                            {/*<td>{order.usdtAmount?.toFixed(2)}</td>*/}
                            <td>{"*" + order.card?.number?.slice(-4)}</td>
                            <td>{order.card?.bank}</td>
                            <td>{order.trafficType}</td>
                            <td>{order.status}</td>
                            {/*<td>{order.traderProfit.toFixed(2)}</td>*/}
                            <td>
                                {order.status === "failed" || order.status === "completed"
                                    ?
                                    <button
                                        className={"gradient-orange-button"}
                                        onClick={() => {
                                            setActiveAppealConstructModal("progress")
                                            setTimeout(() => {
                                                setActiveAppealConstructModal("active")
                                            }, 100)
                                            setOrderToAppealing(order)
                                        }}
                                    >
                                        Апелляция
                                    </button>
                                    :
                                    null
                                }
                            </td>

                        </tr>
                    );
                })}
                </tbody>
                <ModalDialog
                    active={activeAppealConstructModal}
                    setActive={setActiveAppealConstructModal}
                    action={() => {
                        setActiveAppealConfirmModal("progress")
                        setTimeout(() => {
                            setActiveAppealConfirmModal("active")
                        }, 100)
                    }}
                    message={`На какую сумму будет апелляция по заказу #${orderToAppealing?.number}?`}
                    purpose={"appeal"}
                    inputType={"number"}
                    inputValue={amountToAppealing}
                    setInputValue={setAmountToAppealing}
                    inputValue2={checkFile}
                    setInputValue2={setCheckFile}
                    buttonText1={"Далее"}
                />
                <ModalDialog
                    active={activeAppealConfirmModal}
                    setActive={setActiveAppealConfirmModal}
                    action={() => {
                        manualAppealOpen(orderToAppealing, amountToAppealing, checkFile)
                    }}
                    message={`Вы подтверждаете открытие апелляции по заявке #${orderToAppealing?.number} на сумму ${amountToAppealing}р. ?`}
                    purpose={"choice"}
                />

                <ModalDialog
                    active={activeAppealErrorModal}
                    setActive={setActiveAppealErrorModal}

                    message={errorMessage}
                    purpose={"info"}
                    buttonText2={"OK"}
                />
            </table>
            :
            processing
                ?
                <img src={require('../../img/loading.gif')} height={"40px"}
                     className={"margin-left-20"}/>
                :
                <h2>Нет ничего соответствующего вашему запросу</h2>






    );
};

export default SupportOrderTable;