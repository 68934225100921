import React, {useEffect, useState} from 'react';
import $api from "../http";
import "../css/user-creator.css"
import ModalDialog from "./ModalDialog";
import {useNavigate} from "react-router-dom";
import {addDevidersToString} from "./helpers/helper-functions";

const MerchantViewer = ({store}) => {


    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [processing, setProcessing] = useState({status: false, index: null})

    const [merchants, setMerchants] = useState([])

    const [activeModal, setActiveModal] = useState("no")
    const [activePayoffModal, setActivePayoffModal] = useState("no")
    const [activePayoffConfirmModal, setActivePayoffConfirmModal] = useState("no")
    const [activeInfoModal, setActiveInfoModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")


    const [merchant, setMerchant] = useState()
    const [transactionHash, setTransactionHash] = useState()
    const [transactionValue, setTransactionValue] = useState()

    const getMerchants = async () => {
        setLoading(true)
        const response = await $api.get('/get_merchants')
        console.log(response.data)
        setMerchants(response.data)
        setLoading(false)
    }


    const changeMerchantStatus = async (id) => {
        const response = await $api.get(`/change_merchant_status?id=${id}`)

        console.log(response.data)


        const freshMerchant = response.data


        setMerchants(prev => {
            const newMerchantState = prev.map(merchant => {
                if(merchant._id === freshMerchant._id) {
                    return freshMerchant
                } else {
                    return merchant
                }
            })

            return newMerchantState
        })

    }



    const makePayOff = async (transactionHash, merchantId) => {

        try {

            const response = await $api.post('/make_payoff', {
                type: "Merchant",
                transactionHash,
                agentId: merchantId

            })

            if(response.data.result === "OK") {

                const freshMerchantData = response.data.merchantData

                setMerchants(prev => {
                    const newMerchantState = prev.map(merchant => {
                        if(merchant._id === freshMerchantData._id) {
                            return freshMerchantData
                        } else {
                            return merchant
                        }
                    })

                    return newMerchantState


                })

                setModalMessage(`Выплата для ${merchant.login} на сумму ${response.data.amount} USDT зафиксирована`)
                setActiveInfoModal("progress")
                setTimeout(() => {
                    setActiveInfoModal("active")
                }, 100)

            } else {
                setModalMessage(response.data.message)
                setActiveInfoModal("progress")
                setTimeout(() => {
                    setActiveInfoModal("active")
                }, 100)
            }

            console.log(response.data)

        } catch (e) {
            console.log(e)

            setModalMessage(e.message)
            setActiveInfoModal("progress")
            setTimeout(() => {
                setActiveInfoModal("active")
            }, 100)

        }



    }




    // Состояние для отслеживания нажатой кнопки копирования
    const [copiedIndex, setCopiedIndex] = useState(null);

    const handleCopy = (apiKey, index) => {
        navigator.clipboard.writeText(apiKey);
        setCopiedIndex(index);
        // setTimeout(() => setCopiedIndex(null), 2000); // Возвращаем текст обратно через 2 секунды
    };

    // const changeTraderStatus = async (userId, index) => {
    //
    //     setProcessing({status: true, index})
    //
    //     try {
    //         console.log(userId, index)
    //         const response = await $api.get(`/change_trader_status?userId=${userId}`)
    //         console.log(response.data)
    //
    //         // Получаем обновленный объект карты из ответа
    //         const updatedUser = response.data;
    //
    //         // Обновляем состояние карт
    //         setMerchants(prevUsers =>
    //             prevUsers.map(user =>
    //                 user.id === updatedUser.id
    //                     ? { ...user, active: updatedUser.active } // Копируем все поля и меняем только поле active
    //                     : user
    //             )
    //         );
    //
    //     } catch (e) {
    //         console.log(e)
    //     }
    //
    //
    //
    //
    //     setProcessing({status: false, index})
    //
    // }


    useEffect(() => {
        store.setPageTitle("Мерчанты")
        getMerchants()
    }, []);

    return (
        <div className={"user-viewer-wrapper"}>
            <table className="transaction-table">
                <thead>
                <tr>
                    <th>login</th>
                    <th>Баланс [USDT]</th>
                    <th>Ставка [%]</th>
                    {/*<th>Россия min [%]</th>*/}
                    {/*<th>Трансгран [%]</th>*/}
                    {/*<th>Трансгран min [%]</th>*/}
                    {/*<th>NSPK [%]</th>*/}
                    {/*<th>NSPK min [%]</th>*/}
                    <th>Курс USDT</th>
                    {/*<th>API-key</th>*/}
                    <th>Изменение</th>
                    <th>Вкл/Выкл</th>

                </tr>
                </thead>
                <tbody>
                {merchants
                    .slice() // Создаём копию массива, чтобы не мутировать оригинал
                    .sort((a, b) => a.login.localeCompare(b.login)) // Сортируем по user.login
                    .map((merchant, index) => {
                    // const formattedDate = new Date(card.block_timestamp)
                    //     .toLocaleString('ru-RU', options);

                    return (
                        <tr key={merchant.id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                            <td>{merchant.login}</td>
                            <td>
                                <div className={"flex-table-cell-column"}>
                                    <h4 className={"main-admin-data-h"}>{addDevidersToString(merchant.balance?.toFixed(2))}</h4>
                                    <button
                                        onClick={() => {
                                            setActivePayoffModal("progress")
                                            setTimeout(() => {
                                                setActivePayoffModal("active")
                                            }, 100)
                                            setModalMessage(`Выплата для ${merchant.login}`)
                                            setTransactionValue(merchant.balance)
                                            setMerchant(merchant)
                                        }}
                                        className={"gradient-orange-button"}
                                    >Выплата
                                    </button>
                                </div>
                            </td>
                            <td className={"fixed-table-width"}>
                                <h5 className={"main-admin-data-h"}>🡩 RUS | {merchant.stake}</h5>
                                <h5 className={"main-admin-data-h"}>🡫 RUS | {merchant.stakeMini}</h5>
                                <h5 className={"main-admin-data-h"}>🡩 TRANS | {merchant.stakeTransgran}</h5>
                                <h5 className={"main-admin-data-h"}>🡫 TRANS | {merchant.stakeTransgranMini}</h5>
                                <h5 className={"main-admin-data-h"}>🡩 NSPK | {merchant.stakeNspk}</h5>
                                <h5 className={"main-admin-data-h"}>🡫 NSPK | {merchant.stakeNspkMini}</h5>

                            </td>
                            <td>{merchant.usdtSource}</td>
                            {/*<td>*/}
                            {/*    <button*/}
                            {/*        className="copy-button"*/}
                            {/*        onClick={() => handleCopy(merchant.apiKey, index)}*/}
                            {/*    >*/}
                            {/*        {copiedIndex === index ? 'Copied' : 'Copy API-key'}*/}
                            {/*    </button>*/}
                            {/*</td>*/}
                            <td>
                                <button
                                    className={"gradient-orange-button"}
                                    onClick={() => {
                                        store.setMerchantToEditId(merchant._id)
                                        navigate('/create_user')

                                    }}
                                >Редактировать
                                </button>
                            </td>
                            <td>
                                {merchant.active
                                    ?
                                    <button
                                        className={"gradient-red-button"}
                                        onClick={() => {
                                            changeMerchantStatus(merchant._id)

                                        }}
                                    >Отключить
                                    </button>
                                    :
                                    <button
                                        className={"gradient-green-button"}
                                        onClick={() => {
                                            changeMerchantStatus(merchant._id)
                                        }}
                                    >Включить
                                    </button>

                                }
                            </td>
                        </tr>
                    );
                    })}
                </tbody>
            </table>
            {loading
                ?
                <img src={require('../img/loading.gif')} height={"35px"} className={"margin-left-20"}/>
                :
                null
            }
            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                // action={() => {
                //     setActiveModal("progress")
                //     setTimeout(() => {
                //         setActiveModal("no")
                //     }, 100)
                // }}
                message={modalMessage}
                purpose={"info"}
            />
            <ModalDialog
                active={activePayoffModal}
                setActive={setActivePayoffModal}
                purpose={"input"}
                inputPlaceHolder={"Хэш транзакции"}
                inputValue={transactionHash}
                setInputValue={setTransactionHash}
                // input2={true}
                // inputPlaceHolder2={"Сумма USDT"}
                // inputType2={"number"}
                // inputValue2={transactionValue}
                // setInputValue2={setTransactionValue}
                message={modalMessage}
                action={() => {
                    setActivePayoffConfirmModal("progress")
                    setTimeout(() => {
                        setActivePayoffConfirmModal("active")
                    }, 100)
                    setModalMessage(`Подтверждаете выплату для ${merchant.login}?`)
                }}

            />
            <ModalDialog
                active={activePayoffConfirmModal}
                setActive={setActivePayoffConfirmModal}
                purpose={"choice"}
                message={modalMessage}
                action={() => {
                    makePayOff(transactionHash, merchant._id)
                }}
            />

            <ModalDialog
                purpose={"info"}
                message={modalMessage}
                active={activeInfoModal}
                setActive={setActiveInfoModal}
                buttonText2={"OK"}
            />

        </div>
    );
};

export default MerchantViewer;