import React, {useEffect, useRef, useState} from 'react';
import $api from "../http";
import {API_IP} from "../http";
import ModalDialog from "./ModalDialog";
import '../css/devices.css'

const DeviceViewer = ({store, updatedDevice}) => {

    const [connected, setConnected] = useState(false)

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")

    const [processing, setProcessing] = useState({status: false, index: null})

    const [devices, setDevices] = useState([])


    const [filter, setFilter] = useState({})



    const getDevices = async (filter) => {

        filter = filter ? encodeURIComponent(JSON.stringify(filter)) : ""

        const response = await $api.get(`/get_devices?filter=${filter}`)

        console.log(response.data)

        setDevices(response.data)

    }



    useEffect(() => {
        store.setPageTitle("Мобильные устройства")
        getDevices()

    }, []);


    useEffect(() => {

        if(updatedDevice) {

            const deviceIdToCheck = updatedDevice.deviceId;

            const deviceExists = devices.some(device => device.deviceId === deviceIdToCheck);

            if(deviceExists) {

                console.log(updatedDevice)

                setDevices(prevDevices => {
                    const newDeviceState = prevDevices.map(device =>
                        device._id === updatedDevice._id
                            ?
                            updatedDevice.delete
                                ?
                                null
                                :
                                updatedDevice
                            :
                            device
                    )

                    const filteredDeviceState =  newDeviceState.filter(device => device !== null)

                    console.log(filteredDeviceState)
                    return filteredDeviceState

                });
            } else {

                setDevices(prevDevices => [updatedDevice, ...prevDevices]);
            }


        }



    }, [updatedDevice])


    const changeDeviceStatus = async (deviceId, index) => {


        console.log(deviceId, index)

        try {
            setProcessing({status: true, index})

            const response = await $api.get(`/change_device_status?deviceId=${deviceId}`)
            console.log(response.data)


            // Получаем обновленный объект карты из ответа
            const updatedDevices = response.data;

            // Обновляем состояние карт
            setDevices(prevDevices =>
                prevDevices.map(device =>
                    device._id === updatedDevices._id ? updatedDevices : device
                )
            );
            setProcessing({status: false, index})

        } catch (e) {
            setModalMessage("Ошибка смены статуса устройства\n" + e.message + "\n зовите админа")
            setActiveModal("progress")
            setTimeout(() => {
                setActiveModal("active")
            }, 100)
            setProcessing({status: false, index})
        }


    }



    return (
        <div className={"device-viewer-wrapper"}>

            <table className="transaction-table">
                <thead>
                <tr>
                    <th>DeviceID</th>
                    <th>Держатель</th>
                    <th>Банк</th>
                    <th>Карта</th>
                    <th>Status</th>
                    <th className={"fixed-table-width"}>Управление</th>
                    <th className={"fixed-table-width"}>Удаление</th>
                </tr>
                </thead>
                <tbody>
                {devices.map((device, index) => {
                    // const formattedDate = new Date(card.block_timestamp)
                    //     .toLocaleString('ru-RU', options);

                    return (
                        <tr key={device._id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>


                            <td>{device.deviceId}</td>
                            <td>{device.card?.cardholder}</td>
                            <td>{device.card?.bank}</td>
                            <td>{"*" + device.card?.number.slice(-4)}</td>
                            <td>{device.online
                                ?
                                <div className={"green-bold"}>OnLine</div>
                                :
                                <div className={"red-bold"}>OffLine</div>
                            }</td>
                            <td>
                                {device.online //&& device.workStatus
                                    ?
                                    <button
                                        className={"gradient-red-button device-btn"}
                                        disabled={!device.online}
                                        onClick={() => {

                                            changeDeviceStatus(device._id, index)

                                        }}
                                    >Отключить {
                                        processing.status && processing.index === index
                                            ?
                                            <img src={require('../img/loading.gif')} height={"15px"}
                                                 className={"margin-left-20"}/>
                                            :
                                            null
                                    }
                                    </button>
                                    :
                                    device.online
                                        ?
                                        <button
                                            className={"gradient-green-button device-btn"}
                                            disabled={!device.online}
                                            onClick={() => {

                                                changeDeviceStatus(device._id, index)
                                            }}
                                        >Включить {processing.status && processing.index === index ?
                                            <img src={require('../img/loading.gif')} height={"15px"}
                                                 className={"margin-left-20"}/> : null}</button>
                                        :
                                        null

                                }
                            </td>
                            <td>

                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>

            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                // action={() => {
                //     setActiveModal("progress")
                //     setTimeout(() => {
                //         setActiveModal("no")
                //     }, 100)
                // }}
                message={modalMessage}
                purpose={"info"}
            />

        </div>
    );
};

export default DeviceViewer;