import React, {useEffect, useState} from 'react';
import "../css/settings.css"
import "../css/helpers.css"
import $api from "../http";
import BanksTable from "./helpers/BanksTable";
import ModalBankAdder from "./helpers/ModalBankAdder";


const Settings = ({store, merchants}) => {


    const [activeBankAdder, setActiveBankAdder] = useState("no")

    const [merchantOptions, setMerchantOptions] = useState([])


    const [usdtPriceBybit, setUsdtPriceBybit] = useState(0)
    const [usdtFreshPriceBybit, setUsdtFreshPriceBybit] = useState()

    const [usdtPriceGarantex, setUsdtPriceGarantex] = useState(0)
    const [usdtFreshPriceGarantex, setUsdtFreshPriceGarantex] = useState()

    const [stopCrane, setStopCrane] = useState(false)
    const [manualConfirmsLimit, setManualConfirmsLimit] = useState(0)
    const [newManualConfirmsLimit, setNewManualConfirmsLimit] = useState()

    const [newBank, setNewBank] = useState({name: "", code: "", signatures: "", transgran: false})
    const [banks, setBanks] = useState([])


    const [errorMessage, setErrorMessage] = useState({show: false, target: "", message: ""})


    const getBanks = async () => {
        const response = await $api.get('/get_banks')
        console.log(response.data)
        setBanks(response.data)

    }


    const getUsdtPrice = async () => {
        const response = await $api.get('/get_ustd_price_admin')

        console.log(response.data)

        setUsdtPriceBybit(response.data.usdtPriceBybit)
        setUsdtPriceGarantex(response.data.usdtPriceGarantex)
    }


    const getStopCrane = async () => {

        const response = await $api.get('/get_stop_crane')

        console.log(response.data)

        setStopCrane(response.data)

    }


    const manualConfirmsLimitSetter = async (value) => {


        console.log("Start request with", value)

        const response = await $api.post('/set_manual_confirms_limit', {value})

        console.log(response.data)

        setManualConfirmsLimit(response.data)
        setNewManualConfirmsLimit("")

    }

    const refreshStopCrane = async (stopCraneState) => {

        const response = await $api.post('/set_stop_crane', {stopCraneState})

        console.log(response.data)

        setStopCrane(response.data)

    }




    const validateFreshUsdtPrice = async (price, exchange) => {


        if(price < 150 && price > 85) {
            refreshUsdtPrice(price, exchange)
        } else {
            setErrorMessage({show: true, target: "usdt", message: "Давайте нормальный курс установим"})
        }

    }


    const refreshUsdtPrice = async (price, exchange) => {

        try {
            const response = await $api.post('/set_usdt_price', {price, exchange})

            if(exchange === "bybit") {
                setUsdtPriceBybit(response.data)
                setUsdtFreshPriceBybit("")
            } else {
                setUsdtPriceGarantex(response.data)
                setUsdtFreshPriceGarantex("")
            }

        } catch (e) {
            setErrorMessage({show: true, message: e.message})
        }


    }


    function validateBankCode(input) {

        const validPattern = /^[A-Za-z-]*$/;
        const valid = validPattern.test(input)

        if(valid) {
            setNewBank(prev => {
                setErrorMessage({show: false})
                return {
                    ...prev,
                    code: input
                }
            })
        }

    }

    const editBank = async (bank) => {



        setNewBank({
            id: bank._id,
            name: bank.name,
            code: bank.code,
            signatures: bank.signatures.join(', '),
            merchants: bank.merchants,
            transgran: bank.transgran
        })

        setActiveBankAdder("progress")
        setTimeout(() => {
            setActiveBankAdder("active")
            // setTimerSeconds(seconds ?? 10)
            // setTimerRelease(false)
        }, 300)

    }



    const addBank = async (bank) => {


        console.log(bank)


        if(bank.name && bank.code && bank.signatures) {

            try {
                const response = await $api.post("/add_bank", {...bank})

                console.log(response.data)

                if(response.data.status === "OK") {

                    setNewBank({name: "", code: "", signatures: "", id: "", transgran: false})

                    setErrorMessage({show: true, target: "bank", color: "green", message: "Банк добавлен"})

                    setTimeout(() => {
                        setErrorMessage({show: false})
                    }, 2000)

                    setBanks(prev => {
                        const bankInfo = response.data.bankInfo;

                        // Проверяем, существует ли банк с таким _id в массиве
                        const exists = prev.some(bank => bank._id === bankInfo._id);

                        if (exists) {
                            // Если банк уже существует, обновляем его
                            return prev.map(bank =>
                                bank._id === bankInfo._id ? bankInfo : bank
                            );
                        } else {
                            // Если банк не существует, добавляем его в массив
                            return [bankInfo, ...prev];
                        }
                    });
                } else {
                    setErrorMessage({show: true, target: "bank", message: response.data.message})
                }
            } catch (e) {
                setErrorMessage({show: true, target: "bank", message: e.message})
            }



            setActiveBankAdder("progress")
            setTimeout(() => {
                setActiveBankAdder("no")
                // setTimerSeconds(seconds ?? 10)
                // setTimerRelease(false)
            }, 300)




        } else {
            setErrorMessage({show: true, target: "bank", message: "Не все поля заполнены"})
        }

    }


    const getAllAdminSettings = async () => {
        const response = await $api.get('/get_all_admin_settings')

        console.log(response.data)

        setStopCrane(response.data.stopCrane)
        setBanks(response.data.banks)
        setUsdtPriceBybit(response.data.usdtPriceBybit)
        setUsdtPriceGarantex(response.data.usdtPriceGarantex)
        setManualConfirmsLimit(response.data.manualConfirmsLimit)


        setInterval(() => {
            getUsdtPrice()
        }, 60000)


    }



    useEffect(() => {
        store.setPageTitle("Настройки площадки")
        // getUsdtPriceBybit()
        // getStopCrane()
        // getBanks()
        getAllAdminSettings()


    }, []);


    useEffect(() => {
        const merchantOptionsforSelect = merchants.map(merchant => ({value: merchant._id, label: merchant.login}))

        setMerchantOptions(merchantOptionsforSelect)

    }, [merchants])


    return (
        <div className={"settings-wrapper"}>
            <div className={"settings-usdt-wrapper"}>
                <div className={"settings-usdt-header"}>
                    <h5 className={"settings-label"}>Настройки USDT</h5>
                    <div className={"settings-error-message-wrapper"}>
                        {errorMessage.show && errorMessage.target === "usdt"
                            ?
                            <h3 className={`settings-error-message ${errorMessage.color === "green" ? "green-text" : "red-text"}`}>{errorMessage.message}</h3>
                            :
                            null
                        }
                    </div>
                </div>

                <div className={"settings-usdt-setter"}>
                    <div className={"settings-usdt-price-monitor"}>
                        Курс площадки (Bybit)
                        <h1 className={"settings-usdt-value"}>{usdtPriceBybit} ₽</h1>
                    </div>
                    <input
                        placeholder={"Новый курс"}
                        value={usdtFreshPriceBybit}
                        onChange={(e) => {
                            setErrorMessage({show: false, target: "", message: ""})
                            setUsdtFreshPriceBybit(e.target.value)
                        }
                        }
                        type={"number"}
                    />
                    <button
                        onClick={() => {
                            validateFreshUsdtPrice(usdtFreshPriceBybit, "bybit")
                        }}
                        className={"gradient-orange-button"}
                    >Установить
                    </button>
                </div>

                <div className={"settings-usdt-setter"}>
                    <div className={"settings-usdt-price-monitor"}>
                        Курс площадки (Garantex)
                        <h1 className={"settings-usdt-value"}>{usdtPriceGarantex} ₽</h1>
                    </div>
                    <input
                        placeholder={"Новый курс"}
                        value={usdtFreshPriceGarantex}
                        onChange={(e) => {
                            setErrorMessage({show: false, target: "", message: ""})
                            setUsdtFreshPriceGarantex(e.target.value)
                        }
                        }
                        type={"number"}
                    />
                    <button
                        onClick={() => {
                            validateFreshUsdtPrice(usdtFreshPriceGarantex, "garantex")
                        }}
                        className={"gradient-orange-button"}
                    >Установить
                    </button>
                </div>


            </div>

            <div className={"settings-stop-crane-wrapper"}>
                <div className={"settings-stop-crane-header"}>
                    <h5 className={"settings-label"}>СТОП-КРАН</h5>
                    <div className={"settings-error-message-wrapper"}>
                        {errorMessage.show && errorMessage.target === "stop"
                            ?
                            <h3 className={`settings-error-message ${errorMessage.color === "green" ? "green-text" : "red-text"}`}>{errorMessage.message}</h3>
                            :
                            null
                        }
                    </div>
                </div>

                <div className={"settings-stop-crane-setter"}>

                    <div className="circle-container">
                        <div className={`${stopCrane ? "green-circle" : "red-circle"}`}></div>
                    </div>
                    <div className={"settings-stop-crane-indicator-container"}>
                        <h3 className={`stop-crane-indicator ${stopCrane? "red-text" : "green-text"}`}>
                            ПЛОЩАДКА {`${stopCrane ? "ОСТАНОВЛЕНА" : "РАБОТАЕТ" }`}
                        </h3>
                        <button
                            className={`${stopCrane ? "gradient-green-button" : "gradient-red-button"} stop-button`}
                            onClick={() => refreshStopCrane(!stopCrane)}
                        >{stopCrane ? "ПУСК" : "СТОП"}</button>
                    </div>


                </div>

            </div>

            <div className={"settings-banks-wrapper"}>
                <div className={"settings-banks-header"}>
                    <ModalBankAdder
                        active={activeBankAdder}
                        setActive={setActiveBankAdder}
                        value={newBank}
                        setValue={setNewBank}
                        action={() => addBank(newBank)}
                        errorMessage={errorMessage}
                        merchantOptions={merchantOptions}
                    />
                    <h5 className={"settings-label"}>Настройки банков</h5>
                    <div className={"settings-error-message-wrapper"}>
                        {errorMessage.show && errorMessage.target === "bank"
                            ?
                            <h3 className={`settings-error-message ${errorMessage.color === "green" ? "green-text" : "red-text"}`}>{errorMessage.message}</h3>
                            :
                            null
                        }
                    </div>
                </div>

                <div className={"settings-bank-adder"}>
                    {/*<input*/}
                    {/*    type={"text"}*/}
                    {/*    placeholder={"Название"}*/}
                    {/*    value={newBank.name}*/}
                    {/*    onChange={(e) => {*/}
                    {/*        setNewBank(prev => {*/}
                    {/*            setErrorMessage({show: false})*/}
                    {/*            return {*/}
                    {/*                ...prev,*/}
                    {/*                name: e.target.value*/}
                    {/*            }*/}
                    {/*        })*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<input*/}
                    {/*    type={"text"}*/}
                    {/*    placeholder={"код"}*/}
                    {/*    value={newBank.code}*/}
                    {/*    onChange={(e) => validateBankCode(e.target.value)}*/}
                    {/*/>*/}
                    <button
                        className={"gradient-orange-button"}
                        onClick={() => {
                            setActiveBankAdder("progress")
                            setTimeout(() => {
                                setActiveBankAdder("active")
                                // setTimerSeconds(seconds ?? 10)
                                // setTimerRelease(false)
                            }, 300)
                        }}
                    >Добавить</button>
                </div>

                <BanksTable banks={banks} editBank={editBank}/>

            </div>

            <div className={"settings-limits-wrapper"}>
                <h5 className={"settings-label"}>Установки лимитов</h5>
                <div className={"limit-wrapper"}>
                    <label>Ручные подтверждения</label>
                    <div className={"settings-current-value-monitor"}>
                        <h2 className={"margin-0"}>{manualConfirmsLimit}</h2>
                    </div>
                    <input
                        type={"number"}
                        value={newManualConfirmsLimit}
                        onChange={(e) => setNewManualConfirmsLimit(e.target.value)}
                    />
                    <button
                        className={"gradient-orange-button"}
                        onClick={() => {
                            manualConfirmsLimitSetter(newManualConfirmsLimit)
                        }}
                    >Установить</button>
                </div>

            </div>

        </div>
    );
};

export default Settings;