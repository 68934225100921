import React from 'react';
import {addDevidersToString} from "./helper-functions";

const ProfitTableShort = ({profits, store}) => {
    return (
        <table className="transaction-table">
            <thead>
            <tr>
                <th>Дата</th>
                <th>Сумма ({store.user.roles.includes('trader') ? "₽" : "USDT"})</th>
                <th>Статус</th>


            </tr>
            </thead>
            <tbody>
            {profits?.map((profit, index) => {
                // const formattedDate = new Date(card.block_timestamp)
                //     .toLocaleString('ru-RU', options);

                return (
                    <tr
                        key={profit._id}
                        className={
                            index % 2 === 0
                                ?
                                'even-row'
                                :
                                'odd-row'
                        }
                    >

                        <td>{new Date(profit.date).toLocaleString([], {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            // hour: '2-digit',
                            // minute: '2-digit'
                        })}</td>
                        <td>{addDevidersToString(profit.amount.toFixed(2))}</td>
                        <td>{profit.paid ? "Выплачен" : "Не выплачен"}</td>


                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};

export default ProfitTableShort;