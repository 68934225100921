import React, {useEffect, useState} from 'react';
import $api from "../http";
import OrderDailyValueTable from "./helpers/OrderDailyValueTable";
import LoadingSpinner from "./LoadingSpinner";

const WholeStats = ({store}) => {



    const [valueData, setValueData] = useState({})
    const [filter, setFilter] = useState({startDate: new Date()})
    const [processing, setProcessing] = useState(false)


    const getMerchantDailyValue = async  (filter) => {



        const response = await $api.get(`/get_merchant_daily_value?filter=${JSON.stringify(filter)}`)
        const allOrdersForPeriod = response.data

        console.log(allOrdersForPeriod)

        const reducedValues = allOrdersForPeriod.reduce((acc, order) => {

            if(acc[order.merchant.login]) {

                acc[order.merchant.login].startDate = filter.startDate
                acc[order.merchant.login].stopDate = filter.stopDate
                acc[order.merchant.login].ordersCount += 1
                acc[order.merchant.login].amountCount += order.amount

            } else {
                acc[order.merchant.login] = {ordersCount: 1, amountCount: order.amount}
            }


            return acc

        }, {})

        setValueData(reducedValues)
        console.log(reducedValues)

        setProcessing(false)


    }




    useEffect(() => {

        store.setPageTitle("Сводные данные")
        getMerchantDailyValue(filter)


    }, [filter])




    return (
        <div className={"card-viewer-wrapper"}>

            <div className={"order-viewer-merchant-sort-wrapper"}>
                <label>C</label>
                <input
                    type="date"
                    max={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    value={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {
                        setProcessing(true)
                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            startDate: date
                        }));

                        setValueData({});
                        // setSkipCounter(0);
                        //
                        // setTimeout(() => {
                        //     setStopFetching(false);
                        //     setFetching(true);
                        // }, 1000);
                    }}
                />
                <label>По</label>
                <input
                    type="date"
                    min={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    value={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {
                        setProcessing(true)
                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            stopDate: date
                        }));

                        setValueData({});
                        // setSkipCounter(0);

                        // setTimeout(() => {
                        //     setStopFetching(false);
                        //     setFetching(true);
                        // }, 1000);
                    }}
                />

                <button
                    className={"gradient-dark-button"}
                    onClick={() => {
                        setProcessing(true)
                        setFilter({startDate: new Date()})
                        setValueData({})

                        // setSkipCounter(0)
                        //
                        // setTimeout(() => {
                        //     setStopFetching(false)
                        //     setFetching(true)
                        // }, 1000)
                    }}
                >Сброс
                </button>
            </div>


            {Object.keys(valueData).length
                ?
                <OrderDailyValueTable valueData={valueData}/>
                :
                <h3>
                    За {filter.stopDate ? `период с ${ new Date(filter.startDate).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                })} по ${ new Date(filter.stopDate).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                })}` :  new Date(filter.startDate).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                }) } данных нет
                </h3>
            }


            {processing
                ?
                <LoadingSpinner/>
                :
                null
            }
        </div>
    );
};

export default WholeStats;