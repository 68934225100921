import React from 'react';
import "../../css/modal-dialog.css"
import {processMessageText, textProcessor} from "../helpers/helper-functions";
import Select from "react-select";



const ModalBankAdder = ({
                         active, setActive, message,
                         action, value, setValue, errorMessage, merchantOptions

                     }) => {
    return (
        <div className={active === "active"
            ?
            "modal active"
            :
            active === "progress" ? "modal" : "none"}
             onClick={() => {
                 setActive("progress")
                 setTimeout(() => {
                     // setTimerRelease(false)
                     // setTimerSeconds(seconds ?? 10)
                     setActive("no")
                 }, 300)
             }}
        >
            <div className={active === "active"
                ?
                "modal-content active"
                :
                active === "progress" ? "modal-content" : "none"}
                 onClick={(e) => e.stopPropagation()}>

                <div className={"modal-data-wrapper"}>

                    <h3 style={{"margin" : "auto"}}>Добавляем банк</h3>


                    <span className={"modal-message"}>{textProcessor(message)}</span>


                    <div className={"modal-input-container"}>
                        <input

                            className={"modal-input"}
                            placeholder={"Название"}
                            value={value.name}
                            onChange={(e) => setValue(prev => {
                                return {
                                    ...prev,
                                    name: e.target.value
                                }
                            })}
                        />
                        <input

                            className={"modal-input"}
                            placeholder={"Код"}
                            value={value.code}
                            onChange={(e) => setValue(prev => {
                                return {
                                    ...prev,
                                    code: e.target.value
                                }
                            })}
                        />
                        <input

                            className={"modal-input"}
                            placeholder={"Сигнатуры"}
                            value={value.signatures}
                            onChange={(e) => setValue(prev => {
                                return {
                                    ...prev,
                                    signatures: e.target.value
                                }
                            })}

                        />

                        <Select
                            isMulti={true}
                            className={"merchant-bank-selector"}
                            placeholder={"Мерчанты"}
                            value={
                                value.merchants?.length
                                    ? value.merchants.map(merchantValue => ({
                                        value: merchantValue,
                                        label: merchantOptions.find(option => option.value === merchantValue)?.label || 'Неизвестный мерчант'
                                    }))
                                    : [] // Пустой массив для пустого значения
                            }
                            options={merchantOptions}
                            onChange={(selected) => {
                                console.log(selected); // Убедись, что `selected` содержит правильный объект
                                setValue(prev => ({
                                    ...prev,
                                    merchants: selected.map(option => option.value), // Берем только значения, а не объекты
                                }));
                            }}
                        />

                        <div>
                            <input
                                type="checkbox"
                                id="transgran-checkbox"
                                checked={value.transgran}
                                onClick={(e) => setValue(prev => {

                                    console.log(e.target.value)

                                    return {
                                        ...prev,
                                        transgran: !prev.transgran
                                    }
                                })}
                            />
                            <label htmlFor="transgran-checkbox">Трансгран</label>
                        </div>


                        <h3 className={`settings-error-message ${errorMessage.color === "green" ? "green-text" : "red-text"}`}>{errorMessage.message}</h3>

                    </div>

                </div>
                <div className={"decision-btn-container"}>

                    <button

                        className={`gradient-orange-button decision-btn`}
                        onClick={() => {


                            action()


                            // if (errorMessage.target !== "bank") {
                            //     setActive("progress")
                                //     setTimeout(() => {
                                //         setActive("no")
                                //         // setTimerSeconds(seconds ?? 10)
                                //         // setTimerRelease(false)
                                //     }, 300)
                                // }


                            }}>{"Да"}
                        </button>


                    <button

                        className={`gradient-orange-button decision-btn`}
                        onClick={() => {



                            setActive("progress")
                            setTimeout(() => {
                                setActive("no")
                                // setTimerSeconds(seconds ?? 10)
                                // setTimerRelease(false)
                            }, 300)


                        }}>{"Отмена"}
                    </button>
                </div>

            </div>


        </div>
    );
};

export default ModalBankAdder;