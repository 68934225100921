import React, {useEffect, useRef, useState} from 'react';
import $api from "../http";
import {throttle} from "./helpers/helper-functions";
import "../css/sms-viewer.css"
import SmsTable from "./helpers/SmsTable";

const SmsViewer = ({store, newSmsInBd}) => {

    const [smsStore, setSmsStore] = useState([])
    const [skipCounter, setSkipCounter] = useState(0)
    const [fetching, setFetching] = useState(true)
    const [stopFetching, setStopFetching] = useState(false)

    const [processing, setProcessing] = useState(true)


    const startSearchTimer = useRef()


    const [filter, setFilter] = useState({})

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")

    const [loading, setLoading] = useState(false)


    const getSms = async (filter, skip) => {

        setFetching(false)
        setLoading(true)

        const response = await $api(`/get_sms?filter=${encodeURIComponent( JSON.stringify(filter) )}&skip=${skip}`)
        console.log(response.data)

        setProcessing(false)

        if(response.data.stopFetching) {
            setStopFetching(true)
            setSkipCounter(0)
        }



        setSkipCounter(prev => prev + response.data.smsStore.length)
        setSmsStore(prev => [...prev, ...response.data.smsStore])
        setLoading(false)

    }


    useEffect(() => {
        if(Object.keys(newSmsInBd).length) {
            setSmsStore(prev => [newSmsInBd, ...prev])
        }
    }, [newSmsInBd])




    useEffect(() => {
        store.setPageTitle("SMS/Push")

        if(fetching && !stopFetching) {

            clearTimeout(startSearchTimer.current)

            startSearchTimer.current = setTimeout(() => {
                getSms(filter, skipCounter)
            }, 1500)
        }

    }, [fetching, skipCounter, stopFetching])



    useEffect(() => {

        //слушатель события "scroll" повешенный на весь документ
        document.addEventListener('scroll', throttle(scrollHandler, 300))

        //при размонтировании элемента эта функция так же отключает слушатель события
        return function() {
            console.log("DO REMOVE")
            document.removeEventListener('scroll', scrollHandler)
        }

    }, [])


    function scrollHandler(e) {

        console.log("SCROLLING")

        if (e.target.documentElement.scrollHeight - e.target.documentElement.scrollTop < window.innerHeight + 100) {
            setFetching(true)

        }

    }


    return (
        <div className={"sms-viewer-wrapper"}>
            <div className={"order-viewer-merchant-sort-wrapper"}>
                <label>C</label>
                <input
                    type="date"
                    max={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    value={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {
                        setProcessing(true)
                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            startDate: date
                        }));

                        setSmsStore([]);
                        setSkipCounter(0);

                        setTimeout(() => {
                            setStopFetching(false);
                            setFetching(true);
                        }, 1000);
                    }}
                />
                <label>По</label>
                <input
                    type="date"
                    min={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    value={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {
                        setProcessing(true)
                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            stopDate: date
                        }));

                        setSmsStore([]);
                        setSkipCounter(0);

                        setTimeout(() => {
                            setStopFetching(false);
                            setFetching(true);
                        }, 1000);
                    }}
                />
                <select
                    defaultValue={""}
                    onChange={(e) => {
                        setProcessing(true)
                        setFilter(prev => ({
                            ...prev,
                            type: e.target.value
                        }))

                        setSmsStore([])

                        setSkipCounter(0)

                        setTimeout(() => {
                            setStopFetching(false)
                            setFetching(true)
                        }, 1000)
                    }}
                >
                    <option value={""}>Все</option>
                    <option value={"push"}>push</option>
                    <option value={"sms"}>sms</option>
                </select>
                <select
                    defaultValue={""}
                    onChange={(e) => {
                        setProcessing(true)
                        setFilter(prev => ({
                            ...prev,
                            order: e.target.value
                        }))

                        setSmsStore([])

                        setSkipCounter(0)

                        setTimeout(() => {
                            setStopFetching(false)
                            setFetching(true)
                        }, 1000)
                    }}
                >
                    <option value={""}>Все</option>
                    <option value={"unlink"}>Непривязанные</option>

                </select>

                <input
                    placeholder={"По сумме"}
                    type={"number"}
                    value={filter.amount}
                    onChange={(e) => {
                        setProcessing(true)
                        setFilter(prev => ({
                            ...prev,
                            amount: e.target.value
                        }))

                        setSmsStore([])

                        setSkipCounter(0)

                        setTimeout(() => {
                            setStopFetching(false)
                            setFetching(true)
                        }, 1000)
                    }}
                />

                <button
                    className={"gradient-dark-button"}
                    onClick={() => {
                        setProcessing(true)
                        setFilter({amount: "", order: "", type: ""})
                        setSmsStore([])

                        setSkipCounter(0)

                        setTimeout(() => {
                            setStopFetching(false)
                            setFetching(true)
                        }, 1000)
                    }}
                >Сброс</button>


            </div>
            <SmsTable smsStore={smsStore} processing={processing}/>

        </div>
);
};

export default SmsViewer;