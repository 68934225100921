import React from 'react';
import {addDevidersToString} from "./helper-functions";

const OrderDailyValueTable = ({valueData}) => {
    return (
        <table className="transaction-table">
            <thead>
            <tr>
                <th>Дата</th>
                <th>Мерчант</th>
                <th>Заявки</th>
                <th>Общая сумма (р.)</th>


            </tr>
            </thead>
            <tbody>
            {Object.keys(valueData)?.map((merchant, index) => {
                console.log(merchant)

                return (
                    <tr
                        // key={profit._id}`
                        className={
                            index % 2 === 0
                                ?
                                'even-row'
                                :
                                'odd-row'
                        }
                    >

                        <td>{
                            valueData[merchant].stopDate
                                ?
                                new Date(valueData[merchant].startDate).toLocaleString([], {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                }) + " - " +

                                new Date(valueData[merchant].stopDate).toLocaleString([], {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                })
                                :
                                new Date(valueData[merchant].startDate).toLocaleString([], {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                // hour: '2-digit',
                                // minute: '2-digit'
                        })}</td>
                        <td>{merchant}</td>
                        <td>{valueData[merchant].ordersCount}</td>
                        <td>{addDevidersToString(valueData[merchant].amountCount.toFixed(2))}</td>


                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};

export default OrderDailyValueTable;