import React from 'react';
import {addDevidersToString} from "./helper-functions";

const ProfitTableAdmin = ({profits, filter, setProfitToPay, setModalMessage, setActiveModal, currency}) => {
    return (
        <table className="transaction-table">
            <thead>
            <tr>
                <th>Дата</th>
                <th>Агент</th>
                {filter.type
                    ?
                    <th>NickName</th>
                    :
                    null
                }
                <th>Сумма ({currency})</th>
                {filter.type
                    ?
                    <th>Статус</th>
                    :
                    null
                }

                {filter.type
                    ?
                    <th>Управление</th>
                    :
                    null
                }


            </tr>
            </thead>
            <tbody>
            {profits?.map((profit, index) => {
                // const formattedDate = new Date(card.block_timestamp)
                //     .toLocaleString('ru-RU', options);

                const profitDate = new Date(profit.date).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    // hour: '2-digit',
                    // minute: '2-digit'
                })

                return (
                    <tr
                        key={profit._id}
                        className={
                            index % 2 === 0
                                ?
                                'even-row'
                                :
                                'odd-row'
                        }
                    >

                        <td>{profitDate}</td>
                        <td>{profit.userType === "User" ? "Trader" : profit.userType}</td>
                        {filter.type
                            ?
                            <td>{profit.user?.login}</td>
                            :
                            null
                        }
                        <td>{addDevidersToString(profit.amount.toFixed(2))}</td>

                        {filter.type
                            ?
                            <td>{profit.paid ? "Выплачен" : "Не выплачен"}</td>
                            :
                            null
                        }
                        {filter.type
                            ?
                            <td>
                                {profit.paid
                                    ?
                                    null
                                    :
                                    <button
                                        className={"gradient-orange-button"}
                                        onClick={() => {

                                            console.log("setting", profit._id)
                                            setProfitToPay(profit._id)
                                            setModalMessage(`Вы уверены что хотите погасить выплату для ${
                                                filter.type === "User"
                                                    ?
                                                    "трейдера"
                                                    :
                                                    filter.type === "Merchant"
                                                        ?
                                                        "мерчанта"
                                                        :
                                                        "тимлида"
                                                    
                                            }\n${profit.user?.login}\nна сумму ${addDevidersToString(profit.amount.toFixed(2))} USDT\nза ${profitDate}`)

                                            setActiveModal("progress")
                                            setTimeout(() => {
                                                setActiveModal("active")
                                            }, 100)
                                        }}
                                    >Погасить</button>
                                }
                            </td>
                            :
                            null
                        }


                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};

export default ProfitTableAdmin;