import React, {useEffect, useState} from "react";
import "../css/broadcast.css";
import $api from "../http";
import Select from "react-select";

const TelegramBroadcast = ({store, traders}) => {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [selectedTraders, setSelectedTraders] = useState([]);

    const [tradersOptions, setTradersOptions] = useState([])


    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault(); // Предотвращаем переход на новую строку
            handleSendMessage();
        }
    };

    const handleSendMessage = async () => {

        if (inputValue?.trim()) {


            const messageToBot = {
                text: inputValue.trim(),
                to: selectedTraders.length ? selectedTraders : tradersOptions.map(option => option.value)
            }

            const response = await $api.post('/send_broadcast', messageToBot)

            console.log(response.data)

            setMessages((prevMessages) => [
                ...prevMessages,
                { text: inputValue.trim(), id: Date.now() },
            ]);
            setInputValue("");
        }
    };

    const handleDeleteMessage = (id) => {
        setMessages((prevMessages) =>
            prevMessages.filter((message) => message.id !== id)
        );
    };

    const renderMessage = (message) => (
        <div key={message.id} className="message-bubble">
            <div className="delete-button" onClick={() => handleDeleteMessage(message.id)}>
                &times;
            </div>
            {message.text.split("\n").map((line, idx) => (
                <p key={idx} className="message-line">
                    {line}
                </p>
            ))}
        </div>
    );


    useEffect(() => {

        if(traders.length) {
            console.log(traders)

            const options = traders.map(trader => ({value: trader.tgChatId, label: trader.login}))
            console.log(options)
            setTradersOptions(options)

        }

    }, [traders])


    useEffect(() => {

        store.setPageTitle("Рассылка")


    }, [])

    return (
        <div className="telegram-broadcast-container">
            <div className="messages-container">
                {messages.map(renderMessage)}
            </div>
            <div className="input-container">
                <p className="info-text">
                    Сообщения, отправленные здесь, будут пересланы ботом в чаты трейдеров в Телеграм.
                </p>
                <Select
                    placeholder={"Всем"}
                    value={
                        selectedTraders?.map(traderChat => ({
                            value: traderChat,
                            label: tradersOptions.find(option => option.value === traderChat)?.label || traderChat,
                        })) || []
                    }
                    options={tradersOptions}
                    isMulti={true}
                    menuPlacement={"top"}
                    menuPortalTarget={document.body}
                    onChange={(selected) => {
                        const selectedValues = selected.map(option => option.value);
                        setSelectedTraders(selectedValues); // Обновляем только значения
                    }}
                />
                <textarea
                    className="message-input"
                    rows="5"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    placeholder="Введите сообщение..."
                ></textarea>






                <button className="send-button" onClick={handleSendMessage}>
                    Отправить
                </button>
            </div>
        </div>
    );
};

export default TelegramBroadcast;
