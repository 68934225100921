import React from 'react';

const BanksTable = ({banks, editBank}) => {
    return (
        <table className="transaction-table">
            <thead>
            <tr>
                <th>Название</th>
                <th>Код</th>
                <th>Сигны</th>
                <th>Настройки</th>

            </tr>
            </thead>
            <tbody>
            {banks?.sort((a, b) => a.name.localeCompare(b.name)).map((bank, index) => {
                // const formattedDate = new Date(card.block_timestamp)
                //     .toLocaleString('ru-RU', options);

                // console.log(bank)

                return (
                    <tr
                        key={bank._id}
                        className={
                           index % 2 === 0
                                ?
                                'even-row'
                                :
                                'odd-row'
                        }
                    >
                        <td>{bank.name}</td>
                        <td>{bank.code}</td>
                        <td style={{wordWrap: 'break-word', overflowWrap: 'break-word', wordBreak: 'break-word'}}>{bank.signatures.join(", ")}</td>
                        <td>
                            <button
                                className={"gradient-green-button"}
                                onClick={() => editBank(bank)}
                            >Правки</button>
                        </td>


                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};

export default BanksTable;