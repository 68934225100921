import React, {useEffect, useState} from 'react';
import $api from "../http";
import "../css/personal-data.css"
import "../css/loading-spinners.css"
import LoadingSpinner from "./LoadingSpinner";
import ModalDialog from "./ModalDialog";

const TopUps = ({store}) => {


    const options = {
        timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    };

    const fetchLimit = 10

    const [transactions, setTransactions] = useState([])
    const [nextLink, setNextLink] = useState("")

    const [skip, setSkip] = useState(0)
    const [stopFetching, setStopFetching] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")


    const [inputedHash, setInputedHash] = useState("")


    // Состояние для отслеживания нажатой кнопки копирования
    const [copiedIndex, setCopiedIndex] = useState(null);

    const handleCopy = (transactionId, index) => {
        navigator.clipboard.writeText(transactionId);
        setCopiedIndex(index);
        // setTimeout(() => setCopiedIndex(null), 2000); // Возвращаем текст обратно через 2 секунды
    };


    const checkTransaction = async (hash) => {

        setIsLoading(true)

        const response = await $api.post("/check_transaction", {hash})
        console.log(response.data)

        setIsLoading(false)

        if(response.data.result === "OK") {

            const transaction = response.data.transactionData

            setTransactions(prev =>  [transaction, ...prev])
        }

        setModalMessage(response.data.message)
        setActiveModal("progress")
        setTimeout(() => {
            setActiveModal("active")
        }, 100)

    }


    const getTransactions = async (skip) => {

        if (stopFetching) return

        setIsLoading(true)

       // if(link) {
       //     link = encodeURIComponent(link)
       // }


       try {
           const response = await $api.get(`/get_transactions?userId=${store.user.id}&skip=${skip}&limit=${fetchLimit}`)



           if(response.data.transactions) {

               const transactionsFromBd = response.data.transactions

               if(transactionsFromBd.length < fetchLimit) {
                   setStopFetching(true)
               } else {
                   setSkip(prev => prev + transactionsFromBd.length)
               }


               setTransactions(prev => [...prev, ...transactionsFromBd])

               // if(response.data.next) {
               //     setNextLink(response.data.next)
               // } else {
               //     setNextLink("")
               // }




               console.log(response.data)
           }

           if(response.data.message) {
               setModalMessage("Ошибка запроса транзакций\n" + response.data.message + "\n зовите админа")
               setActiveModal("progress")
               setTimeout(() => {
                   setActiveModal("active")
               }, 100)
           }


       } catch (e) {
           setErrorMessage(e.message)
           setModalMessage("Ошибка смены статуса карты\n" + e.message + "\n зовите админа")
           setActiveModal("progress")
           setTimeout(() => {
               setActiveModal("active")
           }, 100)
       } finally {
           setIsLoading(false)
       }




    }


    useEffect(() => {
        store.setPageTitle("Пополнения")
        getTransactions(skip)

    }, [])

    return (
        <div className={"top-up-wrapper"}>

            <div className={"top-up-input-container"}>
                <input
                    className={"top-up-input"}
                    placeholder={`Вставьте сюда хэш транзакции и нажмите "Добавить"`}
                    onChange={(e) => {
                        setInputedHash(e.target.value)
                    }}
                />
                <button
                    className={`${isLoading ? "gradient-light-button" : "gradient-dark-button" } add-transaction-btn`}
                    value={inputedHash}
                    onClick={() => {
                        if(inputedHash) {
                            checkTransaction(inputedHash)
                        }

                    }}

                >Добавить
                    {isLoading
                        ?
                        <img src={require('../img/loading.gif')} height={"15px"} className={"margin-left-20"}/>

                        :
                        null
                    }
                </button>
            </div>

            <table className="transaction-table">
            <thead>
                <tr>
                    <th>Дата</th>
                    <th>Отправитель</th>
                    <th>Сумма (USDT)</th>
                    <th>Сумма (₽)</th>
                    <th>Курс пополнения</th>
                    <th>Хэш</th>
                </tr>
                </thead>
                <tbody>
                {transactions.map((transaction, index) => {
                    const formattedDate = new Date(transaction.date)
                        .toLocaleString('ru-RU', options);

                    return (
                        <tr key={transaction.transaction_id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                            <td>{formattedDate}</td>
                            <td>{transaction.sender}</td>
                            <td>{transaction.amount}</td>
                            <td>{transaction.amountRubles}</td>
                            <td>{transaction.currentUsdtPrice}</td>
                            <td>
                                <button
                                    className="copy-button"
                                    onClick={() => handleCopy(transaction.hash, index)}
                                >
                                    {copiedIndex === index ? 'Copied' : 'Copy hash'}
                                </button>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            {!stopFetching && !isLoading && transactions.length >= fetchLimit
                ?
                <button
                    className={"gradient-dark-button load-transactions-btn"}
                    onClick={() => getTransactions(skip)}
                >Загрузить ещё</button>
                :
                null
            }

            {errorMessage
                ?
                <p>{errorMessage}</p>
                :
                null
            }

            {isLoading
                ?
                <LoadingSpinner/>
                :
                null
            }

            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                // action={() => {
                //     setActiveModal("progress")
                //     setTimeout(() => {
                //         setActiveModal("no")
                //     }, 100)
                // }}
                message={modalMessage}
                purpose={"info"}
                buttonText2={"OK"}
            />
        </div>
    );
};

export default TopUps;