import React, {useState} from 'react';
import $api from "../http";
import "../css/main-admin.css"

const MainAdminTools = ({merchants}) => {



    const [isProcessing, setIsProcessing] = useState(false)
    const [wrongAccountedOrders, setWrongAccountedOrders] = useState([])
    const [sumOfWrongs, setSumOfWrongs] = useState(0)

    const [stateOfResult, setStateOfResult] = useState("Waiting")


    const [currentMerchantId, setCurrentMerchantId] = useState()
    const [currentMerchantProfit, setCurrentMerchantProfit] = useState(0)



    const fixAppealings = async () => {
        const response = await $api.get('/fix_appealings')
        console.log(response.data)
    }


    const makeOrderDownload = async (merchantId) => {
        try {
            // Отправляем запрос на сервер
            const response = await $api.get(`/get_merchant_download_of_orders?merchantId=${merchantId}`, {
                responseType: 'blob', // Указываем, что ожидаем blob (файл)
            });

            // Создаем Blob из данных ответа
            const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Генерируем ссылку для скачивания файла
            const downloadURL = URL.createObjectURL(blob);

            // Создаем элемент ссылки
            const link = document.createElement('a');
            link.href = downloadURL;

            // Назначаем имя файла (вы можете передавать имя с сервера)
            const filename = response.headers['content-disposition']
                ? response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
                : 'file.xlsx';

            link.download = filename;

            // Добавляем ссылку в DOM и кликаем по ней
            document.body.appendChild(link);
            link.click();

            // Удаляем элемент ссылки и освобождаем URL
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadURL);
        } catch (error) {
            console.error('Ошибка при скачивании файла:', error);
        }
    };



    const getCurrentMerchantProfit = async (merchantId) => {

        const response = await $api.get(`/get_current_merchant_profit?merchant_id=${merchantId}`)

        console.log(response.data)
        setCurrentMerchantProfit(response.data.sumOfProfit)

    }


    const fixWrongOrders = async (wrongAccountedOrders) => {

        setIsProcessing(true)
        setStateOfResult("Processing")

        const arrayOfId = wrongAccountedOrders.map(order => order._id.toString())

        try {

            const response = await $api.post('/fix_wrong_orders', {arrayOfId})

            if(response.data === "OK") {
                setStateOfResult("OK")
                setWrongAccountedOrders([])
                setSumOfWrongs(0)
                setTimeout(() => {
                    setStateOfResult("Waiting...")
                }, 3000)


            }

        } catch (e) {
            setStateOfResult("ERROR")
        }


        setIsProcessing(false)

    }


    const getWrongAccountedOrders = async () =>  {

        setIsProcessing(true)
        const response = await $api.get('/get_wrong_accounted_orders')
        console.log(response.data)

        const arrayOfWrongs = response.data


        if(!arrayOfWrongs.length) {
            setStateOfResult("Нет недостач")

            setTimeout(() => {
                setStateOfResult("Waiting...")
            }, 3000)

            return
        }


        setWrongAccountedOrders(arrayOfWrongs)
        const sumCalculation = arrayOfWrongs.reduce((acc, order) => {

            acc = order.amount + acc
            return acc

        }, 0)


        setSumOfWrongs(sumCalculation)
        setIsProcessing(false)
        setStateOfResult("Есть недостачи")


    }



    return (
        <div className={"main-admin-wrapper"}>

            <div></div>

            <div className={"wrong-accounted-orders-wrapper"}>

                <h5 className={"main-admin-settings-label"}>Недорассчитанные заявки</h5>
                <h4 className={"main-admin-data-h"}>{stateOfResult}</h4>

                <button
                    onClick={() => getWrongAccountedOrders()}
                    className={"gradient-orange-button"}
                >
                    Проверить
                </button>

                <h4 className={"main-admin-data-h"}>{wrongAccountedOrders.length ? `${wrongAccountedOrders.length} недостач` : null}</h4>
                <h4 className={"main-admin-data-h"}>{wrongAccountedOrders.length ? `На сумму ${sumOfWrongs}` : null}</h4>
                {wrongAccountedOrders.length
                    ?
                    <button
                        onClick={() => fixWrongOrders(wrongAccountedOrders)}
                        className={"gradient-green-button"}
                    >
                        Пофиксить
                    </button>
                    :
                    null
                }

            </div>


            <div className={"wrong-accounted-orders-wrapper"}>

                <h5 className={"main-admin-settings-label"}>Суммарный профит мерчанта</h5>
                <h4 className={"main-admin-data-h"}>{stateOfResult}</h4>

                <button
                    onClick={() => getCurrentMerchantProfit(currentMerchantId)}
                    className={"gradient-orange-button"}
                >
                    Показать
                </button>

                <select
                    value={currentMerchantId}
                    onChange={(e) => {
                        setCurrentMerchantId(e.target.value)
                        console.log(e.target.value)
                    }}
                >
                    <option value={""}>Выбрать</option>
                    {merchants.map(merchant => {
                        return <option value={merchant._id}>{merchant.login}</option>
                    })}
                </select>

                <h4 className={"main-admin-data-h"}>{currentMerchantProfit}</h4>


            </div>


            <div className={"wrong-accounted-orders-wrapper"}>

                <h5 className={"main-admin-settings-label"}>Выгрузка заявок мерчанта</h5>
                {/*<h4 className={"main-admin-data-h"}>{stateOfResult}</h4>*/}

                <button
                    onClick={() => makeOrderDownload(currentMerchantId)}
                    className={"gradient-orange-button"}
                >
                    Выгрузить
                </button>

                <select
                    value={currentMerchantId}
                    onChange={(e) => {
                        setCurrentMerchantId(e.target.value)
                        console.log(e.target.value)
                    }}
                >
                    <option value={""}>Выбрать</option>
                    {merchants.map(merchant => {
                        return <option value={merchant._id}>{merchant.login}</option>
                    })}
                </select>

                {/*<h4 className={"main-admin-data-h"}>{currentMerchantProfit}</h4>*/}


            </div>


            <div className={"wrong-accounted-orders-wrapper"}>

                <h5 className={"main-admin-settings-label"}>Пофиксить апелляции</h5>
                {/*<h4 className={"main-admin-data-h"}>{stateOfResult}</h4>*/}

                <button
                    onClick={() => fixAppealings()}
                    className={"gradient-orange-button"}
                >
                    Погнали
                </button>

                {/*<select*/}
                {/*    value={currentMerchantId}*/}
                {/*    onChange={(e) => {*/}
                {/*        setCurrentMerchantId(e.target.value)*/}
                {/*        console.log(e.target.value)*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <option value={""}>Выбрать</option>*/}
                {/*    {merchants.map(merchant => {*/}
                {/*        return <option value={merchant._id}>{merchant.login}</option>*/}
                {/*    })}*/}
                {/*</select>*/}

                {/*<h4 className={"main-admin-data-h"}>{currentMerchantProfit}</h4>*/}


            </div>


        </div>
    );
};

export default MainAdminTools;