import React, {useEffect, useState} from 'react';
import {generatePassword} from "./helper-functions";
import Select from "react-select";
import {useNavigate} from "react-router-dom";
import $api from "../../http";
import "../../css/user-creator.css"
import {toJS} from "mobx";

const TraderCreationForm = ({store, createError, setCreateError}) => {

    const navigate = useNavigate()

    const [userData, setUserData] = useState({ allowedWallets: [''] });
    const [supports, setSupports] = useState([])
    const [supportOptions, setSupportOptions] = useState([])


    const [showOk, setShowOk] = useState(false)


    const priorityOptions = [

        {value: 1, label: "1"},
        {value: 2, label: "2"},
        {value: 3, label: "3"},
        {value: 4, label: "4"},
        {value: 5, label: "5"},
        {value: 6, label: "6"},
        {value: 7, label: "7"},
        {value: 8, label: "8"},
        {value: 9, label: "9"},
        {value: 10, label: "10"},
        // {value: "account", label: "Только по номеру счёта"}
    ]


    const getSupports = async () => {
        // setLoading(true)

        console.log(toJS(store.user.id))

        const response = await $api.get(`/get_supports`)
        console.log(response.data)

        setSupports(response.data)
        // setLoading(false)
    }



    const getUserToEdit = async (userId) => {
        const response = await $api.get(`/get_user_by_id?userId=${userId}`)
        console.log(response.data)
        setUserData(prev => {
            setCreateError({show: false})
            return {
                ...prev,
                id: response.data.id,
                login: response.data.login,
                stake: parseFloat(response.data.stake),
                priority: response.data.priority ? parseFloat(response.data.priority) : null,
                allowedWallets: response.data.allowedWallets,
                supports: response.data.supports

            }
        })
    }


    useEffect(() => {

        getSupports()

        if(store.userToEditId) {
            getUserToEdit(store.userToEditId)
        }


        return () => {
            store.setUserToEditId("")
        }


    }, []);


    useEffect(() => {

        const optionsToSet = supports.map(support => ({value: support.id, label: support.login}))
        setSupportOptions(optionsToSet)


    }, [supports])


    // Функция для добавления нового инпута
    const addWalletInput = () => {
        setUserData((prevData) => ({
            ...prevData,
            allowedWallets: ['', ...prevData.allowedWallets],
        }));
    };

    // Функция для удаления инпута по индексу
    const removeWalletInput = (index) => {
        setUserData((prevData) => ({
            ...prevData,
            allowedWallets: prevData.allowedWallets.filter((_, i) => i !== index),
        }));
    };


    const clearFirstInput = () => {
        setUserData((prevData) => ({
            ...prevData,
            allowedWallets: ['', ...prevData.allowedWallets.slice(1)],
        }));
    };

    // Функция для изменения данных инпута
    const handleWalletInputChange = (index, event) => {
        const newWallets = [...userData.allowedWallets];
        newWallets[index] = event.target.value;
        setUserData((prevData) => ({
            ...prevData,
            allowedWallets: newWallets,
        }));
    };

    const handlePaste = (index, event) => {
        event.preventDefault();
        const pastedText = (event.clipboardData || window.clipboardData).getData('text');
        const newWallets = [...userData.allowedWallets];
        newWallets[index] = pastedText;
        setUserData((prevData) => ({
            ...prevData,
            allowedWallets: newWallets,
        }));
    };

    const handleKeyDown = (event) => {
        // Разрешаем только вставку через Ctrl + V и Shift + Insert
        if (
            (event.ctrlKey && (event.key === 'v' || event.key === 'м')) ||
            (event.shiftKey && event.key === 'Insert')
        ) {
            return; // Позволяем обработать событие вставки
        }
        event.preventDefault(); // Запрещаем любые другие действия с клавиатуры
    };



    const createUser = async (userData) => {

        console.log(userData)



        // Список обязательных полей
        const requiredFields = [
            'login',
            'priority',
            'stake',
            'supports',



        ];


        if(!store.userToEditId) {
            requiredFields.push('insurance')
        }


        if(!store.userToEditId) {
            requiredFields.push("password")
        }


        // Проверка на заполнение всех полей
        const allFieldsFilled = requiredFields.every(field => {
            console.log(field, userData[field] !== undefined, userData[field] !== null, userData[field] !== '')
            return userData[field] !== undefined && userData[field] !== null && userData[field] !== '';
        });

        if (allFieldsFilled) {
            // Если все поля заполнены

            console.log(userData)
            console.log("Всё норм создаем юзера")
            try {
                const response = await $api.post('/create_user', {userData: JSON.stringify(userData)})
                if(response.data === "OK") {
                    setShowOk(true)
                    setUserData({
                        'login': "",
                        'password': "",
                        'stake': "",
                        'priority': "",
                        'insurance': "",
                        'allowedWallets': ['']

                    })
                    setTimeout(() => {
                        setShowOk(false)
                        store.setUserToEditId("")
                        navigate('/user_viewer')
                    }, store.userToEditId ? 2000 : 5000)
                } else {
                    setCreateError({show: true, message: response.data.message})
                }

            } catch (e) {
                console.log(e)
                setCreateError({show: true, message: "Ошибка создания юзера"})
            }



        } else {
            // Если какие-то поля не заполнены, выполняем другое действие
            setCreateError({show: true, message: "Заполнены не все поля"})
        }
    };


    function validateInputLogin(input) {

        const validPattern = /^[A-Za-z0-9]*$/;
        const valid = validPattern.test(input)

        if(valid) {
            setUserData(prev => {
                setCreateError({show: false})
                return {
                    ...prev,
                    login: input
                }
            })
        }



    }


    function validateInputStake(input) {

        // Регулярное выражение для чисел от 0.1 до 10, включая дробные
        const validPattern = /^(10|[0-9](\.\d+)?|0\.[1-9]+)?$/;
        const valid = validPattern.test(input);

        if (valid) {
            setUserData(prev => {
                setCreateError({ show: false });
                return {
                    ...prev,
                    stake: input !== "" ? parseFloat(input.toString().replace(",", ".")) : "" // Поле, которое обновляем
                };
            });
        } else {
            setCreateError({ show: true, message: "Введите число от 0.1 до 10" });
        }
    }


    function validateInputInsurance(input) {

        // Регулярное выражение для чисел от 0.1 до 10, включая дробные
        const validPattern = /^(\d+(\.\d+)?|)$/;


        const valid = validPattern.test(input);

        if(input && parseFloat(input) > 200000) {
            setCreateError({ show: true, message: "Введите число до 200 000" });
            return
        }



        if (valid) {
            setUserData(prev => {
                setCreateError({ show: false });
                return {
                    ...prev,
                    insurance: input !== "" ? parseFloat(input.toString().replace(",", ".")) : "" // Поле, которое обновляем
                };
            });
        } else {
            setCreateError({ show: true, message: "Введите число до 200 000" });
        }
    }


    return (
        <div className={"trader-creator-wrapper"}>
            <h1 className={"component-title"}>{store.userToEditId ? "Редактируем" : "Создаём"} трейдера</h1>

            <input
                className={"user-creator-input"}
                type={"text"}
                pattern="[A-Za-z0-9]+"
                placeholder={"Имя (login)"}
                value={userData.login}
                onChange={(e) => {
                    validateInputLogin(e.target.value)

                }}
            />

            <div className={"user-creator-password-input-n-generator-container"}>
                <input
                    readOnly={true}
                    className={"user-creator-input password-input"}
                    type={"text"}
                    placeholder={"password"}
                    value={userData.password}

                />
                <button
                    className={"gradient-dark-button pass-gen-btn"}
                    onClick={() => {
                        const freshPass = generatePassword()
                        setUserData(prev => {
                            setCreateError({show: false})
                            return {
                                ...prev,
                                password: freshPass
                            }
                        })
                    }}
                >{store.userToEditId ? "New Password" : "Generate"}
                </button>
            </div>

            <label
                className={"password-warning red-for-blue"}
            >{userData.password
                ?
                `Скопируйте пароль, после ${store.userToEditId ? 'сохранения' : 'создания'} трейдера его больше нельзя будет увидеть`
                :
                null
            }
            </label>

            {store.userToEditId
                ?
                null
                :
                <input
                    className={"user-creator-input"}
                    // type={"number"}
                    placeholder={"Страховой депозит В РУБЛЯХ"}
                    value={userData.insurance}
                    onChange={(e) => {
                        validateInputInsurance(e.target.value)
                    }}

                />
            }


            <input
                className={"user-creator-input"}
                type={"number"}
                placeholder={"Ставка (%)"}
                value={userData.stake}
                onChange={(e) => {
                    validateInputStake(e.target.value)
                }}

            />

            <Select
                placeholder={"Приоритет"}
                className={"card-creator-selector"}

                value={userData.priority ?
                    {
                        value: userData.priority,
                        label: priorityOptions.find(option => option.value === userData.priority)?.label
                    } : ""
                }
                options={
                    priorityOptions
                }
                onChange={(selected) => {
                    setUserData(prev => {
                        setCreateError({show: false});
                        return {
                            ...prev,
                            priority: selected.value
                        };
                    });
                }}
            />


            <Select
                placeholder={"Саппорт"}
                className={"card-creator-selector"}

                value={userData.supports?.map(supportId => ({
                    value: supportId,
                    label: supportOptions.find(option => option.value === supportId)?.label,
                })) || []}
                options={
                    supportOptions
                }
                isMulti={true}
                onChange={(selected) => {

                    const selectedValues = selected.map(option => option.value);

                    setUserData(prev => {
                        setCreateError({show: false});
                        return {
                            ...prev,
                            supports: selectedValues, // Сохраняем массив значений
                        };
                    });
                }}
            />

            {/*<div className={"user-creator-input-container"}>*/}
            {/*    <input*/}
            {/*        placeholder={"Разрешенный кошелёк"}*/}
            {/*        className={"allowed-wallet-input"}*/}
            {/*        type={"text"}*/}

            {/*    />*/}
            {/*    <button className={"gradient-orange-button"}>*/}
            {/*        +*/}
            {/*    </button>*/}
            {/*</div>*/}

            <div className={"user-creator-input-container"}>
                {/* Обертка для верхнего инпута и кнопки */}
                <div className={"wallet-input-with-add-button"}>
                    <input
                        placeholder={"Разрешенный кошелёк"}
                        className={"allowed-wallet-input"}
                        type={"text"}
                        value={userData.allowedWallets[0]}
                        onChange={(event) => handleWalletInputChange(0, event)}
                        onPaste={(event) => handlePaste(0, event)}
                        onKeyDown={handleKeyDown}
                    />
                    {userData.allowedWallets[0]
                        ?
                        <button className={"gradient-red-button"} onClick={clearFirstInput}>
                            Очистить
                        </button>
                        :
                        null
                    }

                    <button
                        className={"gradient-orange-button"}
                        onClick={addWalletInput}
                        disabled={!userData.allowedWallets[0]}
                    >
                        +
                    </button>


                </div>

                {/* Остальные инпуты */}
                {userData.allowedWallets.slice(1).map((wallet, index) => (
                    <div key={index + 1} className={"wallet-input-wrapper"}>
                        <input
                            placeholder={"Разрешенный кошелёк"}
                            className={"allowed-wallet-input"}
                            type={"text"}
                            value={wallet}
                            onChange={(event) => handleWalletInputChange(index + 1, event)}
                            onPaste={(event) => handlePaste(index + 1, event)}
                            onKeyDown={handleKeyDown}
                        />
                        <button
                            className={"gradient-red-button"}
                            onClick={() => removeWalletInput(index + 1)}
                        >
                            Удалить
                        </button>
                    </div>
                ))}
            </div>


            <div className={"create-user-btn-container"}>
                {createError.show
                    ?
                    <h3 className={"create-user-error-message red-for-blue"}>{createError.message}</h3>
                    :
                    null
                }
                {showOk && !createError.show
                    ?
                    <h3 className={"create-user-success-message"}>Пользователь {store.userToEditId ? "сохранён" : "добавлен"} успешно!</h3>
                    :
                    null
                }
                <button
                    className={"gradient-dark-button create-user-btn"}
                    onClick={() => createUser(userData)}
                >{store.userToEditId ? "Сохранить" : "Создать"}
                </button>
            </div>


        </div>
    );
};

export default TraderCreationForm;